export const CHAT_AI_FEATURES = {
  SEARCH_INQUIRIES: 'BUSCA_INQUERITOS',
  QUERY_LAW: 'CONSULTA_LEIS',
};

export const CHAT_AI_TEXT_TYPES = {
  TEXT: 'text', 
  MARKDOWN: 'markdown',
}

export const CHAT_AI_SENDERS = {
  USER: 'user',
  AI: 'ai',
}