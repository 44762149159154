import http from '../httpService';

const UserService = {
  getProsecutorsService: params => http.get('users/prosecutors', { params }),

  getResponsiblesForInquiryService: (prosecutorOfficeGroupId, params) =>
    http.get(`users/responsible/${prosecutorOfficeGroupId}`, { params }),

  getUsersProsecutorsService: params => http.get('users/prosecutors', { params }),

  getUsers: params => http.get('users', { params }),

  getInstitutionsRegistrationService: () =>
    http.get('inquiry-registration-user-institutions',),

  registerUser: params => http.post('users', params),

  updateUser: (userId, params) => http.patch(`users/${userId}`, params),

  deleteUser: userId => http.delete(`users/${userId}`),
};

export default UserService;
