import http from '../httpService';

const AgreementService = {
  registerAgreementService: params => http.post(`agreements`, params),

  registerProposedService: params => http.post(`agreements/proposed`, params),

  updateProposedService: (agreementId, params) =>
    http.put(`agreements/proposed/${agreementId}`, params),

  getFilesAgreementService: agreementId => http.get(`agreements/${agreementId}/signed-agreement`),

  getFilesAgreementComplianceService: agreementId =>
    http.get(`agreements/${agreementId}/compliance-certificates`),

  hearingCloseService: (hearingId, params) =>
    http.post(`agreements/hearings/${hearingId}/closing`, params),

  agreementsFulfilledService: agreementId => http.get(`agreements/fulfilled/${agreementId}`),

  getSignedAgreementService: agreementId => http.get(`agreements/${agreementId}/signed-agreement`),

  updateInstallmentService: (installmentId, params) =>
    http.post(`agreements/installments/paid/${installmentId}`, params),

  attachInstallmentService: (installmentId, file) =>
    http.post(`agreements/installments/attach/${installmentId}`, file),

  getInstallmentAttachUrlService: inquiryFileId =>
    http.get(`agreements/installments/attach/${inquiryFileId}`),

  resetStatusAgreement: (agreementId, params) =>
    http.post(`agreements/reset-status/${agreementId}`, params),

  updateAgreementStatusService: (agreementId, params) =>
    http.post(`agreements/fulfilled-or-not-fulfilled/${agreementId}`, params),

  homologateAgreementService: (agreementId, params) =>
    http.post(`agreements/hml/${agreementId}`, params),

  uploadComplianceCertificatesService: (agreementId, params) =>
    http.post(`agreements/${agreementId}/compliance-certificates`, params),

  uploadDeclarationOfComplianceService: (agreementId, params) =>
    http.post(`agreements/${agreementId}/declaration-of-compliance`, params),

  getAgreemetDocxService: agreementId => http.get(`agreements/${agreementId}/docx-template`),

  uploadSignedAgreementService: (agreementId, file) =>
    http.post(`agreements/${agreementId}/signed-agreement`, file),

  updateAgreementService: (agreementId, params) => http.put(`agreements/${agreementId}`, params),

  forwardAgreementService: (agreementId, params) =>
    http.post(`agreements/${agreementId}/forward`, params),

  getPdfTemplateAgreementService: agreementId => http.get(`agreements/${agreementId}/pdf-template`),

  cancelAgreementService: agreementId => http.put(`agreements/cancel/${agreementId}`),

  getPenaltyTypes: () => http.get('agreements/penaltyTypes'),

  sendAgreementPropositionService: agreementId =>
    http.put(`agreements/${agreementId}/agreement-prop/email`),

  changeAssociationResponsible: (agreementId, params) =>
    http.post(`agreements/change-association-responsible/${agreementId}`, params),

  getFrequencySheet: agreementId => http.get(`agreements/frequency-sheet/${agreementId}`),

  getAgreementById: agreementId => http.get(`agreements/${agreementId}`),

  getAgreementEvolutionTerms: agreementId => http.get(`agreements/${agreementId}/evolution-terms`),

  postAgreementEvolutionTerm: (agreementId, params) =>
    http.post(`agreements/${agreementId}/evolution-terms`, params),
};

export default AgreementService;
