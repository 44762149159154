import http from '../httpService';

const InquiryService = {
  getPendingCriminalAntecedentsSearches: inquiriesIds =>
    http.get('/inquiries/investigated/pending-criminal-antecedents-searches', {
      params: { inquiriesIds },
    }),

  updateInquiryService: (inquiryId, params) => http.patch(`inquiries/${inquiryId}`, params),

  getInquiryToImportService: policeInquiryNumber =>
    http.get(`/inquiries/neogab/${policeInquiryNumber}`),

  importInquiryService: inquiry => http.post('inquiries/neogab', inquiry),

  getNeogabUrl: params => http.get('neogab', { params }),

  getFileTypesService: params => http.get('file-type', { params }),

  updateInquiryInvestigatedService: (inquiryId, params) =>
    http.patch(`inquiries/investigated/${inquiryId}`, params),

  getInquiriesService: params => http.get('inquiries', { params }),

  getCheckPoliceInquiryNumberService: (newInquiryNumber, params) =>
    http.get(`inquiries/check-police-inquiry-number/${newInquiryNumber}`, { params }),

  registerInquiryService: params => http.post('inquiries', params),

  getInquiriesInvestigatedService: params => http.get('inquiries/investigated', { params }),

  getInquiryInvestigatedDetailService: inquiryInvestigatedId =>
    http.get(`inquiries/investigated/${inquiryInvestigatedId}`),

  getPreventedProsecutorsInquiryService: inquiryId =>
    http.get(`inquiries/${inquiryId}/prevented-prosecutors`),

  addFileInquiryService: (inquiryId, params) =>
    http.post(`inquiries/${inquiryId}/attachments`, params),

  getFilesInquiryService: inquiryId => http.get(`inquiries/${inquiryId}/attachments`),

  getAllFilesInquiryService: (inquiryId, userType) =>
    http.get(`inquiries/${inquiryId}/attachments/all`, { query: { userType } }),

  getUnifiedFileUrl: params => http.get(`inquiries/attachments/combined-files`, { params }),

  getInvestigatedRecordingsService: inquiryInvestigatedId =>
    http.get(`inquiries/investigated/${inquiryInvestigatedId}/recordings`),

  getBoundDocumentsAttachments: inquiryId =>
    http.get(`inquiries/${inquiryId}/bound-documents-attachments`),

  deleteFilesInquiryService: inquiryFileId => http.put(`inquiries/${inquiryFileId}/delete`),

  updateChangeStepService: (idInquiry, params) =>
    http.put(`inquiries/investigated/${idInquiry}/step`, params),

  changeStepAndCreateHearingService: (idInquiry, params) =>
    http.put(`inquiries/investigated/${idInquiry}/step/create-hearing`, params),

  unarchiveOrReopenInquiry: (idInquiryInvestigated, params) =>
    http.put(`inquiries/investigated/${idInquiryInvestigated}/unarchiveOrReopen`, params),

  batchDistributionService: params => http.post('inquiries/batch-distributions', params),

  integrationArquimedesService: inquiryId =>
    http.post(`inquiries/${inquiryId}/internal-system-integration/retry`),

  giveBackInquiryService: (inquiryId, params) =>
    http.post(`inquiries/${inquiryId}/devolution`, params),

  getGeneralReportService: params =>
    http.get(`inquiries/general-report/${params.judicialDistrictId}`, { params }),

  getPendingAndNonpendingService: params =>
    http.get(`inquiries/pending-and-nonpending/`, { params }),

  getPendingAndRegisteredReport: params =>
    http.get(`inquiries/pending-and-registered-report/`, { params }),

  getReportDeadlineService: (judicialDistrictId, params) =>
    http.get(`inquiries/deadline-report/excel/${judicialDistrictId}`, { params }),

  getReportsChartsExcelService: params => http.put(`inquiries/chart-reports/excel`, params),

  findReportDeadlineService: (prosecutorOfficeGroupId, params) =>
    http.get(`inquiries/deadline-report/info/excel/${prosecutorOfficeGroupId}`, { params }),

  getReportProcessingService: judicialDistrictId =>
    http.get(`inquiries/processing-report/${judicialDistrictId}`),

  findReportProcessingService: (prosecutorOfficeGroupId, params) =>
    http.get(`inquiries/processing-report/find/${prosecutorOfficeGroupId}`, { params }),

  updateVictimInquiry: (inquiryId, params) =>
    http.patch(`inquiries/investigated/victims/${inquiryId}`, params),

  updateInvestigatedLegalRepresentative: (inquiryInvestigateId, params) =>
    http.patch(`inquiries/investigated/${inquiryInvestigateId}/legal-representative`, params),

  addNewInvestigatedInToInquiryService: (inquiryId, params) =>
    http.post(`inquiries/${inquiryId}/investigated`, params),

  deleteVictimInquiry: (inquiryId, victimId) =>
    http.delete(`inquiries/investigated/${inquiryId}/victim/${victimId}`),

  deleteLegalRepresentative: (inquiryInvestigatedId, personId) =>
    http.delete(`inquiries/investigated/${inquiryInvestigatedId}/legal-representative/${personId}`),

  getReturnedProseduresService: params =>
    http.get(`inquiries/returned-procedures-report/${params.judicialDistrictId}`, { params }),

  sendInquityToDistributionsService: (inquiryId, params) =>
    http.post(`inquiries/${inquiryId}/distributions`, params),

  redistributionValidateService: investigatedId =>
    http.get(`inquiries/check-redistribution/${investigatedId}`),

  redistributionConfirmService: (inquiryId, params) =>
    http.post(`inquiries/redistribution/${inquiryId}`, params),

  getHistoryInquiryService: inquiryId => http.get(`inquiries/investigated/${inquiryId}/history`),

  getDischargeInquirysService: params => http.get('inquiries/inquiries-to-discharge', { params }),

  batchDischarge: params => http.post('inquiries/batch-discharge', { inquiriesIds: params }),

  singleDischarge: inquiryId => http.post(`inquiries/discharge/${inquiryId}`),

  getInquiriesPendingOrTriage: params => http.get(`inquiries/pending-or-triage/`, { params }),

  getInstitutionsRegistrationByClass: (classCode, params) =>
    http.get(`inquiry-registration-user-institutions/${classCode}`, { params }),

  reopenImportedFromArquimedes: inquiryInvestigatedId =>
    http.put(`inquiries/investigated/${inquiryInvestigatedId}/reopenIp`),

  convertPdfToWord: (fileId, pages) => http.post(`inquiries/convert-pdf/${fileId}`, pages),

  getConvertedPdf: fileId => http.get(`inquiries/converted-pdf/${fileId}`),

  impedimentManifestation: (inquiryId, params) =>
    http.post(`inquiries/investigated/${inquiryId}/impediment-manifestation`, params),

  getInvestigatedForManualSync: () => http.get('inquiries/for-manual-sync'),

  getInvestigatedForManualSyncById: inquiryId => http.get(`inquiries/for-manual-sync/${inquiryId}`),

  manualSynchronizationFromTo: params => http.post(`inquiries/for-manual-sync`, params),

  saveMigration: (inquiryInvestigateId, params) =>
    http.post(`inquiries/migration/${inquiryInvestigateId}`, params),

  backToAnalysisService: inquiryInvestigateId =>
    http.put(`inquiries/investigated/${inquiryInvestigateId}/back-to-analysis`),

  updatePerson: (personId, params) => http.patch(`inquiries/update-person/${personId}`, params),

  backToPreviousStep: (inquiryInvestigateId, params) =>
    http.put(`inquiries/investigated/${inquiryInvestigateId}/back-to-previous-step`, params),

  deleteInquiryService: (inquiryId, params) => http.put(`inquiries/delete/${inquiryId}`, params),
};

export default InquiryService;
