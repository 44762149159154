const generalPenaltiesParser = generals => {
  const newGeneralPenalties = [];
  generals.forEach(general => {
    newGeneralPenalties.push({
      ...general,
      project: general.project,
      association: general.association,
      penalty: 'Outras Penalidades',
    });
  });
  return newGeneralPenalties;
};

export default generalPenaltiesParser;
