/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TodayIcon from '@material-ui/icons/Today';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import SearchIcon from '@material-ui/icons/Search';

export const navigationConfig = customerConfig => [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Visão Geral',
        href: '/Dashboard',
        icon: HomeIcon,
        resource: 'dash',
      },
      {
        title: 'Inquéritos',
        href: '/inqueritos',
        icon: ViewListIcon,
        resource: 'inquiriesRead',
      },
      {
        title: 'Buscar Inquérito',
        href: '/inqueritos-busca',
        icon: SearchIcon,
        resource: 'searchInquiries',
      },
      {
        title: 'Fases',
        href: '#',
        icon: ViewStreamIcon,
        resource: '',
        children: [
          {
            title: 'Triagem',
            href: '/triagem-nao-persecucao',
            resource: 'inquiries/triage',
          },
          {
            title: 'Pesquisa e Distribuição',
            href: '/pesquisa-e-distribuicao',
            resource: 'inquiries/distribution',
          },
          {
            title: 'Análise',
            href: '/analise',
            resource: 'inquiries/analysis',
          },
          {
            title: 'Tramitação e Controle',
            href: '/tramitacao-e-controle',
            resource: 'inquiries/processing',
          },
          {
            title: 'Intimação',
            href: '/intimacao',
            resource: 'inquiries/intimation',
          },
          {
            title: `Audiência${customerConfig.enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`,
            href: '/visualizar-audiencias',
            resource: 'inquiries/audience',
          },
          {
            title: 'Termo de Encaminhamento',
            href: '/termo-de-encaminhamento',
            resource: 'inquiries/forwarding-term',
          },
          {
            title: 'Cumprimento de Acordo',
            href: '/acordo',
            resource: 'inquiries/agreement',
          },
        ],
      },
      {
        title: 'Módulos',
        href: '#',
        icon: ViewModuleIcon,
        resource: '',
        children: [
          {
            title: 'Visualizar Intimações',
            href: '/acompanhar-intimacoes',
            resource: 'inquiries/intimation',
          },
          {
            title: 'Vitaliciamento',
            href: '/vitaliciamento',
            resource: 'prosecutor-lifetime',
          },
          {
            title: 'Sincronização de Investigados',
            href: '/sincronizacao-manual-de-investigados',
            resource: 'manual-synchronization-of-investigated',
          },
          {
            title: 'Consultar Log de Auditoria',
            href: '/log-de-auditoria',
            resource: 'audit-log-view',
          },
          {
            title: 'Atividades Servidores SEMA',
            href: '/atividades-servidores-sema',
            resource: 'sema-server-activities',
          },
        ],
      },
      {
        title: 'Configurações',
        href: '#',
        icon: SettingsIcon,
        resource: '',
        children: [
          {
            title: 'Intimação',
            href: '/configuracao-intimacao',
            resource: 'configuration/intimation-modes',
          },
          {
            title: 'Acesso SDS',
            href: '/configuracao-sds',
            resource: 'configuration/external-credentials',
          },
          {
            title: 'Senha Consensus',
            href: '/configuracao-consensus',
            resource: '',
          },
          {
            title: 'Consultas Dados Socioeconômicos',
            href: '/configuracao-consultas',
            resource: 'configuration/intimation-modes',
          },
          {
            title: 'Google Autenticação',
            href: '/configuracao-google-autenticacao',
            resource: '',
          },
        ],
      },
      {
        title: 'Agenda',
        href: '#',
        icon: TodayIcon,
        resource: '',
        children: [
          {
            title: 'Google Calendar',
            href: '/agenda-promotor/autenticacao',
            resource: '',
          },
          {
            title: 'Agenda Promotor',
            href: '/agenda-promotor/calendario',
            resource: '',
          },
        ],
      },
      {
        title: 'Cadastros',
        href: '#',
        icon: AddIcon,
        resource: '',
        children: [
          {
            title: 'Cadastro de Usuário',
            href: '/cadastro-usuario',
            resource: 'users',
          },
          {
            title: 'Cadastro de Papéis',
            href: '/cadastro-papeis',
            resource: 'roles',
          },
          {
            title: 'Cadastro de Grupo Ofício',
            href: '/cadastro-grupo-oficio',
            resource: 'prosecutor-office-groups',
          },
          {
            title: 'Cadastro de Tipificações',
            href: '/cadastro-tipificacao',
            resource: 'crimes',
          },
          {
            title: 'Cadastro de Serviços e Materiais',
            href: '/cadastro-servicos',
            resource: 'prosecutor-office-groups',
          },
          {
            title: 'Cadastro de Instituições',
            href: '/cadastro-instituicao',
            resource: 'prosecutor-office-groups',
          },
          {
            title: 'Cadastro de Projeto',
            href: '/cadastro-projeto',
            resource: 'projects',
          },
          {
            title: 'Cadastro de Delegacias/Varas',
            href: '/cadastro-delegacias',
            resource: 'prosecutor-office-groups',
          },
          {
            title: 'Solicitação de Serviços e Materiais',
            href: '/solicitacao-servico',
            resource: 'prosecutor-office-groups',
          },
          {
            title: 'Cadastro de Inquéritos',
            href: '/inquerito/cadastro',
            resource: 'inquiriesWrite',
          },
          {
            title: 'Importação de Inquéritos',
            href: '/inquerito/importar',
            resource: 'inquiriesWrite',
          },
          {
            title: 'Cadastro de Leis',
            href: '/cadastro-lei',
            resource: 'laws',
          },
          {
            title: 'Cadastro de Assuntos',
            href: '/cadastro-assuntos',
            resource: 'crime-subject',
          },
          {
            title: 'Cadastro de Instituições SEMA/CEMA',
            href: '/cadastro-sema-cema',
            resource: 'sema-cema-associations',
          },
          {
            title: 'Cadastro de Instituições Parceiras',
            href: '/cadastro-parceiros',
            resource: 'partner-associations',
          },
        ],
      },
      {
        title: 'Relatórios',
        href: '#',
        icon: AssessmentIcon,
        resource: '',
        children: [
          {
            title: 'Performance Grupo Ofício',
            href: '/relatorio/performance-grupo-oficio',
            resource: 'report/prosecutor-office-group-performance',
          },
          {
            title: 'Inquéritos (investigados)',
            href: '/relatorio/inqueritos-investigados',
            resource: 'report/inquiry',
          },
          {
            title: 'Performance da Comarca',
            href: '/relatorio/inqueritos-central',
            resource: 'report/prosecutor-office-performance',
          },
          {
            title: 'Prazos APFD',
            href: '/relatorio/prazos-apfd',
            resource: 'report/apfd-deadline',
          },
          {
            title: 'Requisitórios fora do prazo',
            href: '/relatorio/requisitorios-fora-do-prazo',
            resource: 'report/out-of-date-requests',
          },
          {
            title: 'Distribuições e movimentações',
            href: '/relatorio/distribuicoes-e-movimentacoes',
            resource: 'report/distributions-and-movements',
          },
          {
            title: 'Procedimentos Devolvidos',
            href: '/relatorio/procedimentos-devolvidos',
            resource: 'report/returned-procedures',
          },
          {
            title: 'IPs/APFDs Cadastrados e Pendentes',
            href: '/relatorio/cadastrados-pendentes',
            resource: 'report/pending-and-registered',
          },
          {
            title: 'Procedimentos pendentes ou em triagem',
            href: '/relatorio/pendentes-ou-em-triagem',
            resource: 'report/pending-or-triage-procedures',
          },
          {
            title: 'Inquéritos da base de integração PCPE',
            href: '/relatorio/integracao-pcpe',
            resource: 'report/pcpe-integration',
          },
          {
            title: 'Corregedoria',
            href: '/relatorio/corregedoria',
            resource: 'report/internal-affairs',
          },
          {
            title: 'Auditoria de Procedimento',
            href: '/relatorio/auditoria-procedimento',
            resource: 'report/procedure-audit',
          },
          {
            title: 'Inquéritos Migrados',
            href: '/relatorio/inqueritos-migrados',
            resource: 'report/inquiries-migration',
          },
          {
            title: 'Auditoria de Usuário',
            href: '/relatorio/auditoria-usuario',
            resource: 'report/user-audit',
          },
        ],
      },
      {
        title: 'Distribuição em Lote',
        href: '/distribuicao/lote',
        icon: LineWeightIcon,
        resource: 'inquiries/distribution',
      },
      {
        title: 'Devolução em Lote',
        href: '/devolucao/lote',
        icon: LineWeightIcon,
        resource: 'inquiries/devolution',
      },
      {
        title: 'Baixa de Carga',
        href: '/baixa-de-carga',
        icon: PlayForWorkIcon,
        resource: '',
      },
      {
        title: 'Sair',
        href: '/',
        icon: ExitToAppIcon,
        resource: '',
      },
    ],
  },
];
