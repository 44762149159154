/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Card,
  Divider,
  IconButton,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import history from 'utils/history';
import Moment from 'react-moment';
import '../../font.css';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus, ModalIntegrationInquiry } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import debounce from 'lodash.debounce';
import SearchIcon from '@material-ui/icons/Search';
import { useUserTypes } from 'hooks/useUserTypes';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),
  },
  card: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: theme.spacing(1, 0, 0, 0),
  },
  cardContent: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    marginBottom: 0,
    paddingTop: 5,
    fontFamily: 'roboto-regular',
  },
  itemLeft: {
    marginRight: 5,
    color: '#00597b',
  },
  itemRight: {
    fontSize: 14,
    color: '#00597b',
  },
  containerItem: {
    display: 'flex',
  },
}));

const LinkingInquiry = props => {
  const { inquiry, boundInquiry, refreshPage, write, otherStep = false, canEdit = false } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const initialStates = {
    boundInquiry,
  };

  const [editLinking, setEditLinking] = useState(false);
  const [formState, setFormState] = useState({
    values: { ...initialStates },
  });

  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState([]);

  const [loadingGetInquiries, setLoadingGetInquiries] = useState(false);

  const [openModalIntegration, setOpenModalIntegration] = useState(false);

  const handleEditLinking = () => {
    setInputValue('');
    setFormState(formState => ({
      ...formState,
      values: { ...initialStates },
    }));
    setEditLinking(true);
  };

  const handleOpenModalIntegration = () => {
    setOpenModalIntegration(true);
  };

  const handleCloseModalIntegration = () => {
    setOpenModalIntegration(false);
  };

  const handleEditLinkingToFalse = () => {
    setEditLinking(false);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const handleUpdateLinking = () => {
    const { boundInquiry } = formState.values;

    if (!boundInquiry) {
      toast.warn('Selecione um inquérito para realizar a vinculação');
      return;
    }

    sendRequestVinculation(boundInquiry.id);
  };

  const sendRequestVinculation = id => {
    let params = {
      boundInquiryId: id,
    };
    if (isPM) {
      PmService.updateInquiryService(inquiry.id, params).then(() => {
        toast.success('Inquérito vinculado com sucesso');
        setEditLinking(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiry.id, params).then(() => {
        toast.success('Inquérito vinculado com sucesso');
        setEditLinking(false);
        refreshPage();
      });
    }
  };

  const getInquiry = (search = '') => {
    setLoadingGetInquiries(true);

    let params = {
      page: 1,
      size: 15,
      arquimedesAuto: search,
    };

    inquiryService
      .getInquiriesService(params)
      .then(response => {
        //console.log({ comarcas: response.data.data });
        setOptions(response.data.data);
        if (!response.data.data.length && search.length > 4) {
          handleOpenModalIntegration();
        }
      })
      .finally(() => setLoadingGetInquiries(false));
  };

  const debouncedGetInquiries = useCallback(
    debounce(newValue => getInquiry(newValue), 1000),
    [],
  );

  const updateValueInquiries = newValue => {
    debouncedGetInquiries(newValue);
    setInputValue(newValue);
  };

  useEffect(() => {
    if (editLinking) {
      if (boundInquiry) {
        getInquiry(boundInquiry.arquimedesAuto);
      } else {
        getInquiry();
      }
    }
  }, [editLinking]);

  const handleChangeInquiry = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        boundInquiry: newValue ? newValue : null,
      },
    }));
  };

  const handleOpenViewLinkingInquiries = () =>
    history.push(`/inqueritos-vinculados/${boundInquiry.id}`);

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box flex={3}>
            <Typography className={classes.titulo}>Inquérito Vinculado</Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
            {!editLinking && ((!otherStep && !isPM) || canEdit) ? (
              <Tooltip title="Alterar dados" arrow placement="top">
                <IconButton
                  size="small"
                  onClick={() => (!write ? handleOpenSnackbar() : handleEditLinking())}
                >
                  <CreateIcon style={{ color: '#00b1ac' }} />
                </IconButton>
              </Tooltip>
            ) : (
              editLinking &&
              (!otherStep || canEdit) && (
                <>
                  <Box style={{ marginRight: 10 }}>
                    <Tooltip title="Salvar alterações" arrow placement="top">
                      <IconButton
                        size="small"
                        onClick={handleUpdateLinking}
                        form="my-form-edit"
                        type="submit"
                      >
                        <SaveIcon style={{ color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton size="small" onClick={handleEditLinkingToFalse}>
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {editLinking && (
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Autocomplete
                onInputChange={(event, newInputValue) => updateValueInquiries(newInputValue)}
                getOptionSelected={(option, value) =>
                  option?.arquimedesAuto === value?.arquimedesAuto || null
                }
                getOptionLabel={option => option?.arquimedesAuto || ''}
                filterOptions={x => x}
                value={formState.values?.boundInquiryId || null}
                noOptionsText="Nada foi encontrado"
                onOpen={() => getInquiry(inputValue)}
                loadingText="Buscando..."
                loading={loadingGetInquiries}
                clearOnBlur={false}
                options={options}
                onChange={handleChangeInquiry}
                fullWidth
                renderOption={option => (
                  <Box style={{ width: '100%' }}>
                    <Typography variant="body2">
                      N° arquimedes: {option.arquimedesAuto || 'Não informado'}
                    </Typography>
                    <Typography variant="body2">
                      N° interno: {option.internalSystemNumber || 'Não informado'}
                    </Typography>
                    <Typography variant="body2">
                      N° de origem: {option.policeInquiryNumber || 'Não informado'}
                    </Typography>
                    <Divider />
                  </Box>
                )}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="boundInquiryId"
                    label="Pesquisar código arquimedes"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      endAdornment: (
                        <>
                          {loadingGetInquiries ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <Box ml={1}>
                <Tooltip arrow placement="top" title="Buscar inquérito no arquimedes">
                  <IconButton onClick={handleOpenModalIntegration}>
                    <SearchIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
          {boundInquiry && !editLinking && (
            <Tooltip arrow placement="top" title="Acessar inquéritos vinculados">
              <Card className={classes.card} onClick={handleOpenViewLinkingInquiries}>
                <CardActionArea>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.containerItem}>
                      <Typography variant="h5" className={classes.itemLeft}>
                        Classe:
                      </Typography>
                      <Typography variant="body1" className={classes.itemRight}>
                        {boundInquiry.classeDescription || 'não informado'}
                      </Typography>
                    </Box>
                    <Box className={classes.containerItem}>
                      <Typography variant="h5" className={classes.itemLeft}>
                        Sistema interno:
                      </Typography>
                      <Typography variant="body1" className={classes.itemRight}>
                        {`${boundInquiry.internalSystemNumber} ${boundInquiry.arquimedesAuto}` ||
                          'não integrado'}
                      </Typography>
                    </Box>
                    <Box className={classes.containerItem}>
                      <Typography variant="h5" className={classes.itemLeft}>
                        Data:
                      </Typography>
                      <Typography variant="body1" className={classes.itemRight}>
                        {boundInquiry.fact?.date ? (
                          <Moment format="DD/MM/YYYY" local>
                            {boundInquiry.fact?.date}
                          </Moment>
                        ) : (
                          'não informado'
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.containerItem}>
                      <Typography variant="h5" className={classes.itemLeft}>
                        Local:
                      </Typography>
                      <Typography variant="body1" className={classes.itemRight}>
                        {boundInquiry.fact?.judicialDistrict
                          ? boundInquiry.fact?.judicialDistrict
                          : 'não informado'}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Tooltip>
          )}
          {!boundInquiry && !editLinking && (
            <Box className={classes.containerItem} style={{ marginTop: 5 }}>
              <Typography variant="body1" className={classes.itemRight}>
                Não há vinculado
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {openModalIntegration && !isPM && (
        <ModalIntegrationInquiry
          open={openModalIntegration}
          onClose={handleCloseModalIntegration}
          handleChangeInquiry={handleChangeInquiry}
        />
      )}
    </Box>
  );
};

export default LinkingInquiry;
