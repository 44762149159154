import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Card, Typography, Grid, Tooltip, Box, Divider } from '@material-ui/core';
import Moment from 'react-moment';
import '../../font.css';
import { StepTitle } from '../';
import LockIcon from '@material-ui/icons/Lock';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PolicyIcon from '@material-ui/icons/Policy';
import ReportIcon from '@material-ui/icons/Report';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { limitLengthString } from 'utils/limitLengthString';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from 'react-redux';
import {
  DEADLINE_COLORS,
  getColorForDeadline,
} from 'utils/genericFunctions/getColorForTaskDeadline';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { isInfraction } from 'utils/isInfraction';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(112,112,112,0.95)',
    color: 'rgba(112,112,112,0.95)',
  },
}))(Tooltip);

const CardInquiry = props => {
  const { className, backgroundColor, inquiry, ...rest } = props;
  const { inquiryDisplaySubjects, inquiryDisplayCrimes } = useSelector(
    state => state.customerConfig,
  );

  const useStyles = makeStyles(theme => ({
    root: {
      boxSizing: 'border-box',
      boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 3px 3px 0 rgb(163 163 163)',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    shadow: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1, 1, 1, 2),
      boxShadow: `7px 0px 0px 0px ${backgroundColor} inset`,
    },
    labelCard: {
      color: '#000',
      fontSize: 14,
      fontFamily: 'roboto-regular',
      textAlign: 'center',
    },
    labelComum: {
      fontFamily: 'roboto-regular',
    },
    classBold: {
      fontWeight: 'bold',
    },
    titleCard: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 15,
      marginTop: 10,
      fontFamily: 'roboto-regular',
    },
    numeroInterno: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    cardUserInfo: {
      padding: 5,
      paddingLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: '#000',
      marginTop: theme.spacing(1),
    },
    divScroll: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      maxHeight: 150,
      minHeight: 120,
      textAlign: 'left',
    },
    topDivScroll: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      maxHeight: 85,
    },
    divider: {
      backgroundColor: backgroundColor,
    },
    containerType: {
      color: '#000',
      paddingRight: theme.spacing(0.5),
    },
    containerIsArrested: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 30,
    },
    prosecutorTooltip: {
      color: '#FFF',
    },
  }));

  const classes = useStyles();
  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const TypographyData = props => {
    const { children, variant = 'h6', align = 'left', noWrap } = props;
    return (
      <Typography
        variant={variant}
        noWrap={Boolean(noWrap)}
        style={{ width: '100%', color: '#000', padding: 0, margin: 0, textAlign: align }}
      >
        {children}
      </Typography>
    );
  };

  const getCrimeOrSubject = () => {
    const crimes = inquiryDisplaySubjects
      ? inquiry.inquiry?.crimeSubjects
      : inquiryDisplayCrimes
      ? inquiry?.crimes
      : null;
    let crimeResult;
    if (!inquiryDisplaySubjects && !inquiryDisplayCrimes) {
      return 'Não informado';
    } else {
      if (inquiryDisplaySubjects) {
        crimeResult =
          crimes?.length >= 1
            ? ' ' +
              limitLengthString(
                crimes[0].hierarchy
                  .split(':')
                  .slice(1)
                  .join(':'),
                25,
              )
            : 'Não informado';
      } else if (inquiryDisplayCrimes) {
        crimeResult =
          crimes?.length >= 1 ? ' ' + limitLengthString(crimes[0].taxonomy, 25) : 'Não informado';
      }
      return crimeResult;
    }
  };

  const CircularIndicator = (title, color) => {
    return (
      <Grid container spacing={0}>
        <Grid container item xl={10} lg={10} md={10} sm={10} xs={10}>
          <Box className={classes.topDivScroll}>
            <TypographyData>
              {verifyMaskInquiryNumber(
                inquiry.inquiry?.policeInquiryNumber,
                inquiry.inquiry?.classeDescription,
                allOriginNumbersAreNpu,
              )}
            </TypographyData>
            <TypographyData>
              {inquiry.inquiry.internalSystemNumber
                ? inquiry.inquiry.arquimedesAuto
                  ? `${inquiry.inquiry.internalSystemNumber} ${inquiry.inquiry.arquimedesAuto}`
                  : `${inquiry.inquiry.internalSystemNumber}`
                : 'Número não disponível'}
            </TypographyData>
            <TypographyData>{inquiry.investigated.name}</TypographyData>
            <StepTitle variant="h6" className={classes.labelCard} step={inquiry.step} />
          </Box>
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'flex-end',
          }}
        >
          <Tooltip arrow title={title} placement="top">
            <FiberManualRecordIcon fontSize="small" style={{ color: color }} />
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.shadow}>
        {inquiry.step === STEPS_INQUIRY.ANALISE &&
        inquiry?.hearings?.some(hearing => hearing.status === 'CONFIRMED')
          ? CircularIndicator(`Audiência pendente`, DEADLINE_COLORS.RED)
          : null}
        {inquiry.daysToMovementTaskDeadline
          ? CircularIndicator(
              `Prazo tarefas: ${inquiry.daysToMovementTaskDeadline} dia(s)`,
              getColorForDeadline(inquiry.daysToMovementTaskDeadline),
            )
          : null}
        {inquiry.daysToVictimIntimationDeadline
          ? CircularIndicator(
              `Prazo para envio de documentos a vítima: ${inquiry.daysToVictimIntimationDeadline} dia(s)`,
              getColorForDeadline(inquiry.daysToVictimIntimationDeadline),
            )
          : null}
        {inquiry.pending
          ? CircularIndicator('Com pendência de cumprimento', DEADLINE_COLORS.RED)
          : null}
        {!inquiry.daysToMovementTaskDeadline &&
          !inquiry.daysToVictimIntimationDeadline &&
          !inquiry.pending &&
          !(
            inquiry.step === STEPS_INQUIRY.ANALISE &&
            inquiry?.hearings?.some(hearing => hearing.status === 'CONFIRMED')
          ) && (
            <Box className={classes.topDivScroll}>
              <TypographyData>
                {verifyMaskInquiryNumber(
                  inquiry.inquiry?.policeInquiryNumber,
                  inquiry.inquiry?.classeDescription,
                  allOriginNumbersAreNpu,
                )}
              </TypographyData>
              <TypographyData>
                {inquiry.inquiry.internalSystemNumber
                  ? inquiry.inquiry.arquimedesAuto
                    ? `${inquiry.inquiry.internalSystemNumber} ${inquiry.inquiry.arquimedesAuto}`
                    : `${inquiry.inquiry.internalSystemNumber}`
                  : 'Número não disponível'}
              </TypographyData>
              <TypographyData>{inquiry.investigated.name}</TypographyData>
              <StepTitle variant="h6" className={classes.labelCard} step={inquiry.step} />
            </Box>
          )}
        <Divider className={classes.divider} />
        <Grid container spacing={0} className={classes.divScroll}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData noWrap>
              Classe:{' '}
              {`${inquiry.inquiry?.classe || 'código não informado'}, ${inquiry.inquiry
                ?.classeDescription || 'classe não informada'}`}
            </TypographyData>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData noWrap>
              Grupo Ofício: {inquiry.inquiry?.prosecutorOfficeGroup?.name || 'não informado'}
            </TypographyData>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData>
              CPF do investigado: {inquiry.investigated.taxpayerRegistry || 'não informado'}
            </TypographyData>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData>
              RG do Investigado: {inquiry?.investigated?.rg?.code || 'não informado'}
            </TypographyData>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData noWrap>
              {isInfraction(inquiry.inquiry?.classe) ? 'Ato Infracional' : 'Crime'} cometido:
              {getCrimeOrSubject()}
            </TypographyData>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyData>
              Recebido em:{' '}
              <Moment format="DD/MM/YYYY" local>
                {inquiry.createdAt}
              </Moment>
            </TypographyData>
          </Grid>
          {inquiry.step === STEPS_INQUIRY.ANALISE && inquiry?.lastDistribution?.createdAt && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TypographyData>
                Distribuído em:{' '}
                <Moment format="DD/MM/YYYY" local>
                  {inquiry?.lastDistribution?.createdAt}
                </Moment>
              </TypographyData>
            </Grid>
          )}
        </Grid>
        <Box>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.containerIsArrested}
          >
            <Box display="flex" alignItems="center">
              {inquiry.inquiry.status ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      {inquiry.inquiry.status}
                    </Typography>
                  }
                  placement="top"
                >
                  <HelpIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
              {inquiry.isArrested ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Encontra-se preso
                    </Typography>
                  }
                  placement="top"
                >
                  <LockPersonIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
              {inquiry.inquiry.prosecutor && (
                <HtmlTooltip
                  arrow
                  title={
                    <>
                      <Typography variant="body2" className={classes.prosecutorTooltip}>
                        Promotor: {inquiry.inquiry.prosecutor.name}
                      </Typography>
                      {inquiry.inquiry.prosecutorOffice && (
                        <Typography variant="body2" className={classes.prosecutorTooltip}>
                          Promotoria: {inquiry.inquiry.prosecutorOffice.name}
                        </Typography>
                      )}
                    </>
                  }
                  placement="right"
                >
                  <AccountCircleIcon fontSize="small" />
                </HtmlTooltip>
              )}
              {inquiry.inquiry.isConfidential ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Inquérito confidencial
                    </Typography>
                  }
                  placement="top"
                >
                  <LockIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
              {inquiry.awaitingInstitutionResponse ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Atribuição PM
                    </Typography>
                  }
                  placement="top"
                >
                  <PolicyIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
              {inquiry.inquiry?.fact?.isFlagrant ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Flagrante
                    </Typography>
                  }
                  placement="top"
                >
                  <ReportIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
              {inquiry.inquiry?.hasDiligenceResponse ? (
                <HtmlTooltip
                  arrow
                  title={
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Requisitório
                    </Typography>
                  }
                  placement="top"
                >
                  <CheckCircleIcon fontSize="small" />
                </HtmlTooltip>
              ) : null}
            </Box>
            <div>
              <Typography variant="body1" className={classes.containerType}>
                {inquiry.isProbableNonPersecution ? 'Não persecução' : 'Persecução'}
              </Typography>
            </div>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default CardInquiry;
