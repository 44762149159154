import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  CircularProgress,
  DialogContent,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { toast } from 'react-toastify';
import { colors } from 'utils/colorsSteps';
import HeaderModal from 'components/HeaderModal';
import { formatDateAndHoursToBrazilian } from 'utils/date-time.utils';
import intimationService from 'services/intimation/intimationService';

const useStyles = makeStyles(() => ({}));

const ModalListFIles = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  const { open, onClose, className, intimation, ...rest } = props;

  useEffect(() => {
    if (intimation) {
      getFiles(intimation.id);
    } else {
      toast.info('Não há intimações!');
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiles = (intimationId, _mode) => {
    intimationService.getIntimationConfirmationCertificates(intimationId).then(response => {
      //console.log(response.data.certificates);
      setFiles(response.data.certificates);
      setShowFiles(true);
    });
  };

  const handleGoToWindowRequeriment = file => window.open(file.url, '_blank');

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal backgroundColor={colors.navBar} colorIconTitle="#FFF" onClose={onClose}>
          Confirmação de intimação
        </HeaderModal>
        <DialogContent>
          {!showFiles ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <List>
              {files.length > 0 ? (
                files.map((file, i) => (
                  <ListItem button key={i} onClick={() => handleGoToWindowRequeriment(file)}>
                    <ListItemAvatar>
                      <DescriptionIcon fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Meio de contato: ${file.intimationMode}`}
                      secondary={`Data da confirmação: ${formatDateAndHoursToBrazilian(
                        file.confirmedAt,
                      )}`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography align="center" variant="h5">
                  Não há arquivos
                </Typography>
              )}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalListFIles;
