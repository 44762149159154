import http from '../httpService';

const MovementTaskService = {
  getTasksFilesService: inquiryInvestigatedId =>
    http.get(`movement-tasks/investigated/${inquiryInvestigatedId}/attachments`),

  checkCanBeApplyToAllService: inquiryId =>
    http.get(`movement-tasks/${inquiryId}/check-can-be-apply-to-all`),

  getMovementTasksService: params => http.get('movement-tasks', { params }),

  deleteMovementTasksService: taskId => http.delete(`movement-tasks/${taskId}`),

  updateMovementTasksService: (taskId, params) => http.patch(`movement-tasks/${taskId}`, params),

  registerMovementTasksService: params => http.post('movement-tasks', params),

  getFilesTasksService: taskId => http.get(`movement-tasks/${taskId}`),

  uploadDocumentsInTasksService: (taskId, params) =>
    http.post(`movement-tasks/${taskId}/response-documents`, params),

  movementTaskStartService: inquiryId =>
    http.post(`inquiries/investigated/${inquiryId}/movement-tasks/start`),

  getMovements: params => http.get('movement-tasks/view/movement', { params }),

  updateStatusMovement: (inquiryFileId, params) =>
    http.patch(`movement-tasks/analyse-movement/${inquiryFileId}`, params),

  createNewGoogleDoc: name => http.post(`movement-tasks/google/docs`, { name }),
  
  getSummarizedFileService: summarizedInquiryfileId => http.get(`movement-tasks/summarized-file/${summarizedInquiryfileId}`),

  generateSummarizedFileService: fileId => http.post(`movement-tasks/summarized-file/${fileId}`,  { fileId }),

};

export default MovementTaskService;
