import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Divider,
  Box,
  ListItemIcon,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ClassIcon from '@material-ui/icons/Class';
import CloseIcon from '@material-ui/icons/Close';
import prosecutorOfficeGroupService from 'services/prosecutor-office-group/prosecutorOfficeGroupService';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  msgSearch: {
    minHeight: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ModalListOfficeGroups = props => {
  const { currentOfficeGroupe, onClose, open, onClick } = props;

  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [groups, setGroups] = useState([]);
  const [isResult, setResult] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleClick = (event, group, index) => {
    setSelectedIndex(index);
    onClick(group);
  };

  const initialGroupIndex = groups => {
    const index = groups.findIndex(group => group.id === currentOfficeGroupe.id);
    setSelectedIndex(index);
  };

  useEffect(() => {
    getOfficeGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOfficeGroups = () => {
    prosecutorOfficeGroupService.getOfficeGroupsService().then(response => {
      setGroups(response.data.data);
      if (JSON.stringify(currentOfficeGroupe) !== '{}' && currentOfficeGroupe)
        initialGroupIndex(response.data.data);
      setResult(true);
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle onClose={handleClose} id="simple-dialog-title">
        Selecione um Grupo Ofício
      </DialogTitle>
      <Divider />
      {!isResult && (
        <Box className={classes.msgSearch}>
          <Typography variant="h5">Buscando...</Typography>
        </Box>
      )}
      {isResult && (
        <List component="nav">
          {groups.map((group, index) => (
            <Box key={group.id}>
              <ListItem
                selected={selectedIndex === index}
                button
                onClick={event => handleClick(event, group, index)}
              >
                <ListItemIcon>
                  <ClassIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={group.name} />
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      )}
    </Dialog>
  );
};

export default ModalListOfficeGroups;
