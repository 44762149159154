const materialParser = materialsForAssociations => {
  const newArrayMaterial = [];
  materialsForAssociations.forEach(material => {
    const newMaterial = {
      ...material,
      amount: material.amount,
      deliveryDeadline: material.deliveryDeadline?.substring(0, 10),
      material: material?.material,
      penalty: 'Materiais',
      parcels: material?.installments?.map(i => ({
        ...i,
        amount: i?.value ?? 0,
        date: i.initialDate,
      })),
      relation: {
        amount: material.targetNecessity?.amount,
        active: material.targetNecessity?.active,
        association: material.targetNecessity?.association,
        material: material.targetNecessity?.material,
        finalDate: material.targetNecessity?.finalDate.substring(0, 10),
        inicialDate: material.targetNecessity?.inicialDate.substring(0, 10),
        id: material.targetNecessity?.id,
        description: material.targetNecessity?.description,
        project: material.project,
      },
    };
    newArrayMaterial.push(newMaterial);
  });
  return newArrayMaterial;
};

export default materialParser;
