import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Divider } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { ButtonConsensus } from 'components';
import ModalListFiles from 'components/DivNumeroRequerimento/components/ModalListFiles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#d7d7d7',
    borderRadius: 20,
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
  },
  containerListFiles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInquiryNumber: {
    textAlign: 'right',
    color: colors.navBar,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  textClasse: {
    color: colors.navBar,
  },
}));

const OriginNumberCompany = props => {
  const { inquiry } = props;

  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const classes = useStyles();

  const [openModalListFile, setOpenModalListFIle] = useState(false);

  const handleCloseModalListFiles = () => {
    setOpenModalListFIle(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={7} lg={7} md={8} sm={8} xs={12} className={classes.containerText}>
          <Typography variant="h5" className={classes.textInquiryNumber}>
            Número de Origem:{' '}
            {verifyMaskInquiryNumber(
              inquiry.inquiry.policeInquiryNumber,
              inquiry.inquiry.classeDescription,
              allOriginNumbersAreNpu,
            ) || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={5} lg={5} md={4} sm={4} xs={12} className={classes.containerListFiles}>
          <ButtonConsensus
            title="Ver Inquérito"
            onClick={() => setOpenModalListFIle(true)}
            startIcon={<AssignmentIcon />}
            backgroundColor={colors.greenLight}
            hovercolor={colors.greenLight}
            margin={0}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider style={{ height: 2 }} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.containerText}>
          <Typography variant="h5" className={classes.textClasse}>
            Classe do inquérito: {inquiry.inquiry?.classeDescription || 'não informado'}
          </Typography>
        </Grid>
      </Grid>
      {openModalListFile && (
        <ModalListFiles
          investigated={inquiry.inquiry.investigated}
          inquityInvestigatedId={inquiry.id}
          open={openModalListFile}
          agreement={inquiry.agreement}
          onClose={handleCloseModalListFiles}
        />
      )}
    </div>
  );
};

export default OriginNumberCompany;
