import { PermissionResources } from 'constants/permission-resources';
import { toast } from 'react-toastify';

const handlePermissionWrite = (handleOnClick, write = false) => {
  return write ? handleOnClick() : handleSnackbarWriteDeny();
};

const handleSnackbarWriteDeny = (message = 'Você não tem permissão para tomar esta ação.') => {
  toast.warn(message);
  return;
};

const handleAllRoles = (roles = []) => {
  return roles.map(role => ({
    name: role.name,
    hierarchy: role.hierarchy,
  }));
};

const handleAllPermissions = (permissions = []) => {
  let permissionsAll = {};
  Object.entries(PermissionResources).forEach(([key, value]) => {
    let permission = permissions.find(item => item.resource === value);

    if (!permission) {
      permission = {
        read: false,
        write: false,
        resource: value,
      };
    }
    permissionsAll[key] = permission;
  });

  let permissionsObject = {
    inquiries: permissionsAll['INQUIRIES'],
    inquiriesTriagem: permissionsAll['TRIAGE'],
    inquiriesDistribuicao: permissionsAll['DISTRIBUTION'],
    inquiriesAnalise: permissionsAll['ANALYSIS'],
    inquiriesIntimacao: permissionsAll['INTIMATION'],
    inquiriesArquivo: permissionsAll['ARCHIVE'],
    inquiriesAgreement: permissionsAll['AGREEMENT'],
    inquiriesDevolvido: permissionsAll['RETURNED'],
    inquiriesRemetidoAoJudiciario: permissionsAll['SENT_TO_JUDICIARY'],
    inquiriesRemetidoAoSistemaInterno: permissionsAll['SENT_TO_INTERNAL_SYSTEM'],
    inquiriesRequisitorio: permissionsAll['RESPONSE_OF_SOLICITATION'],
    inquiriesForwardingTerm: permissionsAll['FORWARDING_TERM'],
    inquiriesDeclinacao: permissionsAll['DECLINE'],
    inquiriesPersecucao: permissionsAll['PERSECUTION'],
    inquiriesDiligencia: permissionsAll['FORWARD_DUE_DILIGENCE'],
    inquiriesAudience: permissionsAll['AUDIENCE'],
    inquiriesDenuncia: permissionsAll['DENUNCIATION'],
    inquiriesConfigurarIntimacao: permissionsAll['INTIMATION_MODE_CONFIG'],
    inquiriesSenhaSds: permissionsAll['EXTERNAL_CREDENTIALS_CONFIG'],
    inquiriesUsuario: permissionsAll['USERS'],
    inquiriesPapeis: permissionsAll['ROLES'],
    inquiriesGrupoOficio: permissionsAll['PROSECUTOR_OFFICE_GROUPS'],
    inquiriesTasks: permissionsAll['PROCESSING'],
    associations: permissionsAll['ASSOCIATIONS'],
    crimesPermission: permissionsAll['CRIMES'],
    consultasNaoDistribuidas: permissionsAll['UNDISTRIBUTED_INQUIRIES'],
    servicesAndMaterials: permissionsAll['MATERIAL_AND_COMMUNITY_SERVICE'],
    projects: permissionsAll['PROJECTS'],
    relatorioRequisitoriosForaDoPrazo: permissionsAll['OUT_OF_DATE_REQUESTS_REPORT'],
    relatorioDistribuiçõesEmovimentações: permissionsAll['DISTRIBUTIONS_AND_MOVEMENTS_REPORT'],
    relatorioProcedimentosDevolvidos: permissionsAll['RETURNED_PROCEDURES_REPORT'],
    relatorioCadastradosEpendentes: permissionsAll['PENDING_AND_REGISTERED_REPORT'],
    relatorioPendentesOuEmTriagem: permissionsAll['PENDING_OR_TRIAGE_PROCEDURES_REPORT'],
    prosecutorOfficeGroupPerformanceReport:
      permissionsAll['PROSECUTOR_OFFICE_GROUP_PERFORMANCE_REPORT'],
    inquiryReport: permissionsAll['INQUIRY_REPORT'],
    prosecutorOfficePerformanceReport: permissionsAll['PROSECUTOR_OFFICE_PERFORMANCE_REPORT'],
    apfdDeadlineReport: permissionsAll['APFD_DEADLINE_REPORT'],
    pcpeIntegrationReport: permissionsAll['PCPE_INTEGRATION_REPORT'],
    procedureAuditReport: permissionsAll['PROCEDURE_AUDIT_REPORT'],
    organs: permissionsAll['ORGANS'],
    solicitationMaterialAndService: permissionsAll['SOLICITATION_MATERIAL_AND_COMMUNITY_SERVICE'],
    intimationView: permissionsAll['INTIMATION_VIEW'],
    discharge: permissionsAll['DISCHARGE'],
    batchDistribution: permissionsAll['BATCH_DISTRIBUTION'],
    schedule: permissionsAll['SCHEDULE'],
    searchInquiries: permissionsAll['SEARCH_INQUIRIES'],
    socioeconomicSearch: permissionsAll['SOCIOECONOMIC_DATA_SEARCH'],
    receivedDocuments: permissionsAll['RECEIVED_DOCUMENTS'],
    overviewExcel: permissionsAll['OVERVIEW_EXCEL'],
    registerInquiries: permissionsAll['REGISTER_INQUIRIES'],
    prosecutorLifetime: permissionsAll['PROSECUTOR_LIFETIME'],
    internalAffairsReport: permissionsAll['INTERNAL_AFFAIRS'],
    crimeSubjectPermission: permissionsAll['CRIME_SUBJECT'],
    lawsPermission: permissionsAll['LAWS'],
    partnerAssociationsPermission: permissionsAll['PARTNER_ASSOCIATIONS'],
    manualSynchronizationOfInvestigated: permissionsAll['MANUAL_SYNCHRONIZATION_OF_INVESTIGATED'],
    semaAndCemaAssociationsPermission: permissionsAll['SEMA_AND_CEMA_ASSOCIATIONS'],
    auditLogView: permissionsAll['AUDIT_LOG_VIEW'],
    errorImportArquimedesIntegrationReportView:
      permissionsAll['ERROR_IMPORT_ARQUIMEDES_INTEGRATION_REPORT_VIEW'],
    inquiriesMigration: permissionsAll['MIGRATION'],
    inquiriesMigrationReport: permissionsAll['INQUIRIES_MIGRATION_REPORT'],
    deletePendingInquiry: permissionsAll['DELETE_PENDING_INQUIRY'],
    inquiryDelete: permissionsAll['DELETE_INQUIRY'],
    aiAssistant: permissionsAll['AI_ASSISTANT'],
    aiAuditReport: permissionsAll['AI_AUDIT_REPORT'],
    userAuditReport: permissionsAll['USER_AUDIT_REPORT'],
    confidentialInquiry: permissionsAll['CONFIDENTIAL_INQUIRY'],
    viewInquiryOtherGroupOffice: permissionsAll['VIEW_INQUIRY_OTHER_GROUP_OFFICE'],
    backToPreviousStep: permissionsAll['BACK_TO_PREVIOUS_STEP'],
    semaServerActivities: permissionsAll['SEMA_SERVER_ACTIVITIES'],
  };

  return permissionsObject;
};

export { handlePermissionWrite, handleSnackbarWriteDeny, handleAllPermissions, handleAllRoles };
