import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDateToBrazilian } from 'utils/date-time.utils';
import { changeNameStep } from 'utils/changeNameStep';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),
  },
}));

const TableFiles = props => {
  const {
    files,
    fileType,
    handleClick,
    openModalDelete,
    handleOpenRecording,
    handleSuperCertificateClick,
    onlyView,
  } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const classes = useStyles();

  const handleClickDelete = (fileId, event) => {
    openModalDelete(fileId, event);
  };

  const handleClickFile = (event, file) => {
    switch (fileType) {
      case 'agreementFiles':
        handleClick(event, file);
        break;
      case 'generalFiles':
        handleClick(event, file);
        break;
      case 'hearingFiles':
        handleOpenRecording(file);
        break;
      case 'agreementComplianceFiles':
        handleClick(event, file);
        break;
      case 'superCertificateFiles':
        handleSuperCertificateClick();
        break;
      case 'tasksFiles':
        handleClick(event, file);
        break;
      case 'requisitionFiles':
        handleClick(event, file);
        break;
      default:
        break;
    }
  };

  return (
    <Box className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box flex={3}>
          {files.length > 0 && (
            <TableContainer component={Paper} className={classes.container}>
              <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Fase</TableCell>
                    <TableCell align="center">Descrição</TableCell>
                    <TableCell align="center">Nome do Arquivo</TableCell>
                    <TableCell align="center">Data</TableCell>
                    {fileType === 'hearingFiles' && (
                      <TableCell align="center">Realizada em</TableCell>
                    )}
                    {fileType !== 'superCertificateFiles' &&
                      fileType !== 'requisitionFiles' &&
                      !onlyView && <TableCell align="center">Ações</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map(file => (
                    <TableRow
                      key={file.id}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={event => handleClickFile(event, file)}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {file?.fileUploadStep
                            ? changeNameStep(file?.fileUploadStep, customerConfig)
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">{file?.description}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">{file?.name}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {formatDateToBrazilian(file?.createdAt)}
                        </Typography>
                      </TableCell>
                      {fileType === 'hearingFiles' && (
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="body2">{file?.hearing?.date}</Typography>
                        </TableCell>
                      )}
                      {file?.fileType?.displayOnScreen &&
                        fileType !== 'superCertificateFiles' &&
                        fileType !== 'requisitionFiles' &&
                        !onlyView && (
                          <TableCell component="th" scope="row" align="center">
                            <Tooltip arrow placement="top" title="Remover arquivo">
                              <IconButton onClick={event => handleClickDelete(file.id, event)}>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default TableFiles;
