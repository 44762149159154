import React, { useState } from 'react';
import { ButtonConsensus } from 'components';
import '../../font.css';
import { ModalListOfficeGroups, ModalConfirm } from './componentes';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';

const ChangeOfficeGroupInquiry = props => {
  const { currentOfficeGroupe, refreshPage, write, disabled } = props;

  const [open, setOpen] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [group, setGroup] = useState(null);
  const [sendRequest, setSendRequest] = useState(false);

  const { inquiryId } = useSelector(state => state.inquiry);

  const closeModals = () => {
    handleCloseModalConfirm();
    handleCloseModal();
    setGroup(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleOpenModal = () => {
    if (write) {
      setOpen(true);
    } else {
      handleOpenSnackbar();
    }
  };

  const handleOpenModalConfirm = group => {
    setGroup(group);
    setOpenModalConfirm(true);
  };

  const changeOfficeGroupe = group => {
    let params = {
      prosecutorOfficeGroupId: group.id,
    };

    setSendRequest(true);

    inquiryService
      .updateInquiryService(inquiryId, params)
      .then(() => {
        toast.success('Grupo Ofício alterado com sucesso');
        refreshPage();
        closeModals();
      })
      .finally(() => setSendRequest(false));
  };

  const handleChangeOfficeGroup = () => {
    changeOfficeGroupe(group);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <>
      <ButtonConsensus title="SECRETARIAS" onClick={handleOpenModal} disabled={disabled} />
      {open && (
        <ModalListOfficeGroups
          currentOfficeGroupe={currentOfficeGroupe}
          open={open}
          onClose={handleCloseModal}
          onClick={handleOpenModalConfirm}
        />
      )}
      {openModalConfirm && (
        <ModalConfirm
          open={open}
          onClose={handleCloseModalConfirm}
          onClick={handleChangeOfficeGroup}
          group={group}
          sendRequest={sendRequest}
        />
      )}
    </>
  );
};

export default ChangeOfficeGroupInquiry;
