import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Menu, Box, Tooltip, Button } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import { setGroupReducer, setSelectedIndexGroup } from '../../../../../../actions';
import { ModalChangeGroup } from './components';
import { limitLengthString } from 'utils/limitLengthString';

const useStyles = makeStyles(() => ({
  grupoOficioStyle: {
    fontFamily: 'Roboto-Regular',
    color: '#ffffff',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 'normal',
  },
}));

export default function GroupsMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChangeGroup, setOpenChangeGroup] = useState(false);
  const [manipulateIndex, setManipulateIndex] = useState(null);
  const [manipulateGroup, setManipulateGroup] = useState(null);

  const groups = useSelector(state => state.logedUser.groups);

  const { selectedIndexGroup } = useSelector(state => state.groupMenu);

  const handleCloseModalGroup = () => {
    setOpenChangeGroup(false);
  };

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = index => {
    dispatch(setGroupReducer(manipulateGroup));
    dispatch(setSelectedIndexGroup(index));
    setManipulateIndex(null);
    setManipulateGroup(null);
    setAnchorEl(null);
  };

  const handleOpenModalGroup = (group, index) => {
    if (selectedIndexGroup !== index) {
      setManipulateIndex(index);
      setManipulateGroup(group);
      setOpenChangeGroup(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box style={{ marginRight: 10 }}>
        <Tooltip arrow title="Grupo ofício">
          <Button onClick={handleClickListItem} className={classes.grupoOficioStyle}>
            {selectedIndexGroup === null
              ? 'Grupo Ofício'
              : limitLengthString(groups[selectedIndexGroup]?.name)}
            <GroupIcon style={{ marginLeft: 5 }} />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {groups.length > 0 ? (
          groups.map((group, index) => (
            <MenuItem
              key={index}
              selected={index === selectedIndexGroup}
              onClick={() => handleOpenModalGroup(group, index)}
            >
              {group?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>Não há grupo ofício cadastrado</MenuItem>
        )}
      </Menu>
      {openChangeGroup && (
        <ModalChangeGroup
          open={openChangeGroup}
          onClose={handleCloseModalGroup}
          indexGroup={manipulateIndex}
          group={manipulateGroup}
          confirmChange={handleMenuItemClick}
        />
      )}
    </div>
  );
}
