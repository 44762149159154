/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CachedIcon from '@material-ui/icons/Cached';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  listClassesAtosInfracionais,
  listEstadoCivil,
  listOrgaoEmissorRg,
} from 'utils/constsFormInvestigateds';
import { listUf } from 'utils/citysPE';
import { ModalConfirm } from './components';
import {
  InputConsensus,
  ButtonConsensus,
  AlternativePhones,
  ComboBoxMultiSelect,
} from 'components';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import { useUserTypes } from 'hooks/useUserTypes';
import { cpfMask, cnpjMask } from 'masks/masks';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { formatedTaxpayerRegister } from 'utils/genericFunctions/formatedTazpayerRegisterNumber';
import { clearSpecialCharacterName } from 'utils/genericFunctions/clearSpecialCharacterName';
import crimeService from 'services/crime/crimeService';
import inquiryService from 'services/inquiries/inquiryService';
import judicialDistrictsService from 'services/judicial-district/judicialDistrictsService';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import pmService from 'services/PM/pmService';
import personService from 'services/person/personService';
import _ from 'lodash';
import lawService from 'services/law/lawService';
import PmService from 'services/PM/pmService';
import history from 'utils/history';
import ModalConfirmChangePersonType from './components/ModalConfirmChangePersonType/ModalConfirmChangePersonType';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const FormPersonalData = props => {
  const {
    peoples = [],
    otherInvestigateds = [],
    refreshPage = null,
    write = false,
    otherStep = false,
    inquiryClasse = null,
    canEdit = false,
    enableChangePersonType = false,
    visualizeOnly = false,
  } = props;

  const { isPM } = useUserTypes();

  const useStyles = makeStyles(() => ({
    root: {
      padding: 10,
    },
    titulo: {
      color: '#00597b',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 20,
      paddingLeft: 10,
    },
    infoInvestigated: {
      color: '#00597b',
      textAlign: 'left',
      fontSize: 12,
      paddingLeft: 10,
    },
    containerLabelInputCrime: {
      padding: 5,
    },
    containerLabelInput: {
      padding: 5,
    },
    selectForm: {
      height: 25,
      outline: 0,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: '#ededed',
    },
    form: {
      heigth: '100%',
      width: '100%',
      padding: '0 10px 0 10px',
    },
    labelForm: {
      display: 'flex',
      fontSize: 11,
    },
    labelFormCrime: {
      display: 'flex',
      fontSize: 11,
      color: '#00597b',
    },
    inputForm: {
      display: 'block',
      height: 25,
      outline: 0,
      padding: 5,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: 'transparent',
    },
    inputFormCrime: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      color: '#00597b',
      textAlign: 'left',
    },
    inputFormCrimeEdit: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      color: '#00597b',
      borderBottom: '1px solid #000',
    },
    inputMaterial: {
      width: '100%',
    },
    table: {
      width: '100%',
    },
    containerCrimeGrid: {
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 20,
    },
    textCrime: {
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    autoContainer: {
      marginTop: -240,
      '@media (max-width:600px)': {
        marginTop: -340,
        Height: 130,
        maxHeight: 300,
        minHeight: 300,
        maxWidth: '100%',
        minWidth: '100%',
        position: 'relative',
      },
      maxHeight: 200,
      minHeight: 200,
      //maxWidth: 300,
      minWidth: 300,
      position: 'relative',
      zIndex: 1,
      //Height: 130,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#ededed',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #292929',
    },
    containerCrime: {
      position: 'relative',
      '@media (max-width:600px)': {
        maxHeight: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#b9b9b9',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
    },
    addCrimeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 5,
      //width: '98.5%',
      minHeight: 80,
      maxHeight: 160,
      overflow: 'auto',
      //backgroundColor: '#f7f7f7',
      border: '1px solid #999',
      borderRadius: 5,
      marginTop: 5,
    },
    textoCrimeContainer: {
      color: '#00b1ac',
      fontWeight: 'bold',
      borderBottom: '1px solid #999',
      paddingBottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 40,
      textAlign: 'justify',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();

  const inquiryIdReducer = useSelector(state => state.inquiry.inquiryId);

  const initialValues = {
    peopleType: 'investigated',
    crimes: [],
    rg: {
      code: '',
      issuingBody: '',
      issueDate: '',
      uf: '',
    },
    address: {
      publicPlace: '',
      address: '',
      neighborhood: '',
      city: '',
      number: '',
      zipCode: '',
      block: '',
      apartment: '',
      house: '',
      court: '',
      lot: '',
      complement: '',
      uf: '',
      latitude: '',
      longitude: '',
      full: '',
      street: '',
    },
    placeOfBirth: {
      country: '',
      city: '',
      uf: '',
    },
    email: '',
    motherName: '',
    birthDate: '',
    phone: '',
    nationality: '',
    maritalStatus: '',
    taxpayerRegistry: '',
    pis: '',
    name: '',
    type: 'PF',
    phones: [],
  };

  const [formState, setFormState] = useState({
    isValid: false,
    investigated: [],
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [showForm, setShowForm] = useState(false);
  const [selectedInvestigated, setSelectedInvestigated] = useState(null);
  const [editInvestigatedInArray, setEditInvestigatedInArray] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [disabledBtnSds, setDisabledBtnSds] = useState(false);
  const [disabledBtnCep, setDisabledBtnCep] = useState(false);
  const [showCrimeInput, setShowCrimeInput] = useState(true);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [taxpayerRegistryMask, setTaxpayerRegistryMask] = useState(cpfMask);

  const [selectedLaw, setSelectedLaw] = useState(null);
  const [inputSearchLaw, setInputSearchLaw] = useState('');
  const [optionsLaw, setOptionsLaw] = useState([]);
  const [loadingLaws, setLoadingLaws] = useState(false);
  const [pageLaws, setPageLaws] = useState(1);
  const [lastPageLaws, setLastPageLaws] = useState(1);

  const [crimesCometidos, setCrimesCometidos] = useState([]);
  const [inputSearchCrime, setInputSearchCrime] = useState('');
  const [loadingCrimes, setLoadingCrimes] = useState(false);
  const [pageCrimes, setPageCrimes] = useState(1);
  const [lastPageCrimes, setLastPageCrimes] = useState(1);
  const [keyCrime, setKeyCrime] = useState(false);
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [subtypeOptions, setSubtypeOptions] = useState([]);
  const [subtypesSelected, setSubtypesSelected] = useState([]);
  const [editingSubtypes, setEditingSubtypes] = useState(false);

  const [showInputPhones, setShowInputPhones] = useState(false);
  const [phones, setPhones] = useState([]);

  const [cities, setCities] = useState([]);
  const [labelCities, setLabelCities] = useState('Cidade');
  const [cityObject, setCityObject] = useState(null);
  const [isInfraction, setIsInfraction] = useState(false);
  const [investigatedForRepresentative, setInvestigatedForRepresentative] = useState(null);

  const [showPhysicalFields, setShowPhysicalFields] = useState(true);
  const [gridSize, setGridSize] = useState(6);

  const [changePersonTypeJustification, setChangePersonTypeJustification] = useState('');
  const [openModalConfirmChangePersonType, setOpenModalConfirmChangePersonType] = useState(false);
  const [isChangeToInvestigated, setIsChangeToInvestigated] = useState(false);

  const getCities = () => {
    let params = {
      uf: formState.values.address.uf,
    };

    if (isPM) {
      pmService.getJudicialDistrictsPmService(params).then(response => {
        setCities(response.data.data);
        if (formState?.values?.address?.city) {
          setCityObject(
            response.data.data.find(
              comarca =>
                comarca?.name?.toLowerCase() === formState?.values?.address?.city.toLowerCase(),
            ) || null,
          );
        }
        if (response.data.data.length < 1) {
          setLabelCities('Cidades não disponíveis');
        } else {
          setLabelCities('Cidade ');
        }
      });
    } else {
      judicialDistrictsService.getJudicialDistrictsService(params).then(response => {
        setCities(response.data.data);
        if (formState?.values?.address?.city) {
          setCityObject(
            response.data.data.find(
              comarca =>
                comarca?.name?.toLowerCase() === formState?.values?.address?.city.toLowerCase(),
            ) || null,
          );
        }
        if (response.data.data.length < 1) {
          setLabelCities('Cidades não disponíveis');
        } else {
          setLabelCities('Cidade ');
        }
      });
    }
  };

  const handleChangeJudicialDistrict = (event, newValue) => {
    setCityObject(newValue);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleCloseModalConfirmChangePersonType = () => {
    setOpenModalConfirmChangePersonType(false);
  };

  const handleAddInvestigated = () => {
    setPhones([]);
    setShowInputPhones(true);
    setShowForm(true);
    setEditInvestigatedInArray(false);
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  const handleChangeStateEditToFalse = () => {
    setShowForm(false);
    setEditInvestigatedInArray(false);
    setSelectedLaw(false);
  };

  const requestZipCode = () => {
    const { zipCode } = formState.values.address;

    if (zipCode === '') {
      toast.warn('Informe um CEP');
      return;
    }

    setDisabledBtnCep(true);

    personService
      .getZipCodeService(zipCode)
      .then(response => {
        const res = response.data;
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            address: {
              ...formState.values.address,
              city: res.city !== '' ? res.city : formState.values.address?.city || '',
              neighborhood:
                res.neighborhood !== ''
                  ? res.neighborhood.toUpperCase()
                  : formState.values.address?.neighborhood,
              street:
                res.street !== '' ? res.street.toUpperCase() : formState.values.address?.street,
              uf: res.uf !== '' ? res.uf : formState.values.address?.uf,
              //complement: res.complement !== "" ? res.complement : formState.values.address?.complement
            },
          },
        }));
        setCityObject(
          cities.find(comarca => comarca.name.toLowerCase() === res.city.toLowerCase()) || null,
        );
      })
      .finally(() => setDisabledBtnCep(false));
  };

  const handleUpdateInvestigated = investigated => {
    setEditInvestigatedInArray(true);
    setSelectedInvestigated(investigated);
    setShowForm(true);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        id: investigated.id,
        rg: {
          code: investigated.rg ? investigated.rg.code : '',
          issuingBody: investigated.rg ? investigated.rg.issuingBody : '',
          issueDate: investigated.rg ? investigated.rg.issueDate : '',
          uf: investigated.rg ? investigated.rg.uf : '',
        },
        address: {
          publicPlace: investigated.address ? investigated.address.publicPlace : '',
          address: investigated.address ? investigated.address.address : '',
          neighborhood: investigated.address ? investigated.address.neighborhood : '',
          city: investigated.address ? investigated.address.city : '',
          number: investigated.address ? investigated.address.number : '',
          zipCode: investigated.address ? investigated.address.zipCode : '',
          block: investigated.address ? investigated.address.block : '',
          apartment: investigated.address ? investigated.address.apartment : '',
          house: investigated.address ? investigated.address.house : '',
          court: investigated.address ? investigated.address.court : '',
          lot: investigated.address ? investigated.address.lot : '',
          complement: investigated.address ? investigated.address.complement : '',
          uf: investigated.address ? investigated.address.uf : '',
          latitude: investigated.address ? investigated.address.latitude : '',
          longitude: investigated.address ? investigated.address.longitude : '',
          full: investigated.address ? investigated.address.full : '',
          street: investigated.address ? investigated.address.street : '',
        },
        placeOfBirth: {
          country: investigated.placeOfBirth ? investigated.placeOfBirth.country : '',
          city: investigated.placeOfBirth ? investigated.placeOfBirth.city : '',
          uf: investigated.placeOfBirth ? investigated.placeOfBirth.uf : '',
        },
        email: investigated.email,
        motherName: investigated.motherName,
        birthDate: investigated.birthDate,
        phone: investigated.phone,
        nationality: investigated.nationality,
        maritalStatus: investigated.maritalStatus,
        taxpayerRegistry: investigated.taxpayerRegistry,
        peopleType: investigated.peopleType,
        //crimeNome: '',
        name: investigated.name,
        type: investigated.type,
      },
    }));
    if (investigated.phones) {
      setPhones(investigated.phones);
    } else {
      setPhones([]);
    }
    setShowInputPhones(false);
  };

  const handleChangePeopleType = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeMaritalStatus = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeName = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          clearSpecialCharacterName(event.target.value)?.toUpperCase() ?? event.target.value,
      },
    }));
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value !== undefined && event.target.value !== null
            ? event.target.value.toUpperCase()
            : event.target.value,
      },
    }));
  };

  const handleChangeAddress = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        address: {
          ...formState.values.address,
          [event.target.name]: event.target.value
            ? event.target.value.toUpperCase()
            : event.target.value,
        },
      },
    }));
  };

  const handleChangeRg = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        rg: {
          ...formState.values.rg,
          [event.target.name]: event.target.value,
        },
      },
    }));
  };

  const handleChangeplaceOfBirth = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        placeOfBirth: {
          ...formState.values.placeOfBirth,
          [event.target.name]:
            event.target.value !== undefined && event.target.value !== null
              ? event.target.value.toUpperCase()
              : event.target.value,
        },
      },
    }));
  };

  const requestSds = () => {
    let params = {
      rg: formState.values?.rg?.code,
      uf: formState.values?.rg?.uf,
    };

    if (!params.rg || !params.uf) {
      toast.warn('Informe o número e uf do RG!');
      return;
    }

    if (params.uf !== 'PE') {
      toast.warn(`No momento não disponibilizamos buscas à SDS do UF ${params.uf}.`);
      return;
    }

    setDisabledBtnSds(true);

    personService
      .getSdsDataService(params)
      .then(response => {
        if (selectedInvestigated) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              name: response?.data?.name || selectedInvestigated.name,
              taxpayerRegistry:
                response?.data?.taxpayerRegistry || selectedInvestigated.taxpayerRegistry,
              motherName: response?.data?.motherName || selectedInvestigated.motherName,
              birthDate: response?.data?.birthDate || selectedInvestigated.birthDate,
              phone: response?.data?.phone || selectedInvestigated.phone,
              maritalStatus: response?.data?.maritalStatus || selectedInvestigated.maritalStatus,
              nationality: response?.data?.nationality || selectedInvestigated.nationality,
              rg: {
                ...formState.values.rg,
                issueDate: response?.data?.rg?.issueDate || selectedInvestigated.rg?.issueDate,
                uf: response?.data?.rg?.uf || selectedInvestigated.rg?.uf,
                code: response?.data?.rg?.code || selectedInvestigated.rg?.code,
                issuingBody:
                  response?.data?.rg?.issuingBody || selectedInvestigated.rg?.issuingBody,
              },
              placeOfBirth: {
                ...formState.values.placeOfBirth,
                city: response?.data?.placeOfBirth?.city || selectedInvestigated.placeOfBirth?.city,
                uf: response?.data?.placeOfBirth?.uf || selectedInvestigated.placeOfBirth?.uf,
                country:
                  response?.data?.placeOfBirth?.country ||
                  selectedInvestigated.placeOfBirth?.country,
              },
              // address: {
              //     ...formState.values.address,
              //     street: typeof response.data.address !== 'undefined' ? response.data.address.full : '',
              //     //address: selectedInvestigated.address.address,
              //     apartment: selectedInvestigated.address.apartment,
              //     block: selectedInvestigated.address.block,
              //     city: selectedInvestigated.address.city,
              //     complement: selectedInvestigated.address.complement,
              //     court: selectedInvestigated.address.court,
              //     house: selectedInvestigated.address.house,
              //     latitude: selectedInvestigated.address.latitude,
              //     longitude: selectedInvestigated.address.longitude,
              //     lot: selectedInvestigated.address.lot,
              //     neighborhood: selectedInvestigated.address.neighborhood,
              //     number: selectedInvestigated.address.number,
              //     publicPlace: selectedInvestigated.address.publicPlace,
              //     //street: selectedInvestigated.address.street,
              //     uf: selectedInvestigated.address.uf,
              //     zipCode: selectedInvestigated.address.zipCode
              // },
            },
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              name: response?.data?.name || '',
              taxpayerRegistry: response?.data?.taxpayerRegistry || '',
              motherName: response?.data?.motherName || '',
              birthDate: response?.data?.birthDate || '',
              phone: response?.data?.phone || '',
              maritalStatus: response?.data?.maritalStatus || '',
              nationality: response?.data?.nationality || '',
              rg: {
                ...formState.values.rg,
                issueDate: response?.data?.rg?.issueDate || '',
                issuingBody: response?.data?.rg?.issuingBody || '',
                uf: response?.data?.rg?.uf || '',
              },
              placeOfBirth: {
                ...formState.values.placeOfBirth,
                city: response?.data?.placeOfBirth?.city || '',
                uf: response?.data?.placeOfBirth?.uf || '',
              },
              // address: {
              //     ...formState.values.address,
              //     street: typeof response.data.address !== 'undefined' ? response.data.address.full : ''
              // },
            },
          }));
        }
        toast.success('Dados recebidos com sucesso!');
      })
      .finally(() => setDisabledBtnSds(false));
  };

  const handleDeleteInvestigated = (_, person, __) => {
    setSelectedInvestigated(person);
    setOpenModalConfirm(true);
    //deleteVictim(victim.id)
  };

  const deleteVictim = async (personId, peopleType) => {
    if (peopleType === 'legalRepresentative') {
      await inquiryService.deleteLegalRepresentative(investigatedForRepresentative, personId);
      toast.success('Representante excluído com sucesso!');
    } else {
      await inquiryService.deleteVictimInquiry(inquiryIdReducer, personId);
      toast.success('Vítima excluída com sucesso!');
    }
    refreshPage();
    setShowForm(false);
    setEditInvestigatedInArray(false);
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  const updateInvestigated = (
    investigated,
    changedToVictim = false,
    changedToInvestigated = false,
  ) => {
    delete investigated.pis;
    delete investigated.peopleType;
    delete investigated.crimes;

    setDisabledSubmitButton(true);

    inquiryService
      .updatePerson(investigated.id, investigated)
      .then(() => {
        toast.success('Dados alterados com sucesso!');
        setShowForm(false);
        setEditInvestigatedInArray(false);
        setFormState(formState => ({
          ...formState,
          values: { ...initialValues },
        }));
        const otherInvestigated = otherInvestigateds[0];
        if (changedToInvestigated || changedToVictim) {
          setOpenModalConfirmChangePersonType(false);
          toast.success(
            `O tipo de pessoa foi alterado para: ${
              changedToInvestigated ? 'Investigado' : 'Vítima'
            }`,
          );
          if (changedToVictim) {
            setTimeout(() => {
              history.replace(
                redirectToInquiryIdStep(
                  { id: otherInvestigated.id, step: otherInvestigated.step },
                  false,
                ),
              );
              history.goBack();
            }, 4000);
          } else {
            refreshPage();
          }
        } else {
          refreshPage();
        }
      })
      .finally(() => setDisabledSubmitButton(false));
  };

  const addVictim = victim => {
    const params = {
      victims: [victim],
    };
    setDisabledSubmitButton(true);

    if (isPM) {
      PmService.updateVictimInquiry(inquiryIdReducer, params)
        .then(() => {
          toast.success('Vítima adicionada com sucesso!');
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    } else {
      inquiryService
        .updateVictimInquiry(inquiryIdReducer, params)
        .then(() => {
          toast.success('Vítima adicionada com sucesso!');
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    }
  };

  const addRepresentative = person => {
    setDisabledSubmitButton(true);
    const params = {
      person,
    };
    if (isPM) {
      PmService.updateInvestigatedLegalRepresentative(investigatedForRepresentative, params)
        .then(() => {
          toast.success('Representante Legal adicionado com sucesso!');
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    } else {
      inquiryService
        .updateInvestigatedLegalRepresentative(investigatedForRepresentative, params)
        .then(() => {
          toast.success('Representante Legal adicionado com sucesso!');
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    }
  };

  const addNewInvestigated = people => {
    setDisabledSubmitButton(true);
    setShowInputPhones(true);

    if (isPM) {
      PmService.addNewInvestigatedInToInquiryService(inquiryIdReducer, people)
        .then(() => {
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          toast.success('Investigado cadastrado com sucesso!');
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    } else {
      inquiryService
        .addNewInvestigatedInToInquiryService(inquiryIdReducer, people)
        .then(() => {
          refreshPage();
          setShowForm(false);
          setEditInvestigatedInArray(false);
          toast.success('Investigado cadastrado com sucesso!');
          setFormState(formState => ({
            ...formState,
            values: { ...initialValues },
          }));
        })
        .finally(() => setDisabledSubmitButton(false));
    }
  };

  const getLaws = (search = '') => {
    setLoadingLaws(true);

    setPageLaws(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw(response.data.data);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetLaws = useCallback(
    debounce(newValue => getLaws(newValue), 500),
    [],
  );

  const updateValueLaws = newValue => {
    if (!optionsLaw.find(law => law.name === newValue)) {
      debouncedGetLaws(newValue);
      setInputSearchLaw(newValue);
    }
  };

  const handleChangeLaw = (event, newValue) => {
    setSelectedLaw(newValue);
  };

  const loadMoreLawsResults = () => {
    const nextPage = pageLaws + 1;

    setPageLaws(nextPage);

    setLoadingLaws(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchLaw,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw([...optionsLaw, ...response.data.data]);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  const handleScrollLaw = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageLaws < lastPageLaws && !loadingLaws) {
      loadMoreLawsResults();
    }
  };

  const getCrimes = (search = '') => {
    setLoadingCrimes(true);

    setPageCrimes(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos(response.data.data);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCrimes = useCallback(
    debounce(newValue => getCrimes(newValue), 500),
    [],
  );

  const updateValueCrimes = newValue => {
    if (!crimesCometidos.find(crime => crime.taxonomy === newValue)) {
      debouncedGetCrimes(newValue);
      setInputSearchCrime(newValue);
    }
  };

  const changeSubtypesSelected = () => {
    const { crimes } = formState.values;

    const index = crimes.findIndex(crime => crime.id === selectedCrime.id);
    const subtypes = subtypesSelected?.map(sub => {
      return subtypeOptions.find(subtype => subtype.id === sub.id || subtype.id === sub);
    });

    if (index !== -1) {
      const updatedCrimes = [...crimes];

      updatedCrimes[index] = {
        ...updatedCrimes[index],
        subtypesSelected: subtypes || [],
      };

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          crimes: updatedCrimes,
        },
      }));
    }

    setSelectedCrime({ ...selectedCrime, subtypesSelected: subtypes });
  };

  const handleChangeCrime = () => {
    if (!selectedCrime) {
      return toast.warn('Selecione um crime para ser adicionado');
    }
    if (!editingSubtypes) {
      handleAddCrime();
    } else {
      changeSubtypesSelected();
    }
    setSelectedCrime(null);
    setSubtypesSelected([]);
    setEditingSubtypes(false);
  };

  const handleChangeCrimeAutocomplete = (event, newValue) => {
    setSelectedCrime(newValue);
  };

  const handleAddCrime = () => {
    const newValue = selectedCrime;
    const crimeExists = formState.values.crimes.some(crime => crime.id === newValue.id);

    if (crimeExists) {
      return toast.warn('O crime selecionado já existe na lista');
    }

    if (subtypesSelected?.length) {
      const subtypes = subtypesSelected.map(sub => subtypeOptions.find(item => item.id === sub));
      newValue.subtypesSelected = subtypes;
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: [...formState.values.crimes, newValue],
      },
    }));
    setKeyCrime(!keyCrime);
  };

  const loadMoreCrimesResults = () => {
    const nextPage = pageCrimes + 1;

    setPageCrimes(nextPage);

    setLoadingCrimes(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchCrime,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos([...crimesCometidos, ...response.data.data]);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  const handleScrollCrimes = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageCrimes < lastPageCrimes && !loadingCrimes) {
      loadMoreCrimesResults();
    }
  };

  const handleRemoveCrime = (event, crimeId) => {
    event.persist();
    let newListCrimes = formState.values.crimes.filter(crime => crime.id !== crimeId);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: newListCrimes,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    const { name } = formState.values;

    if (name === '' || !name) {
      toast.warn('Prencha o nome.');
      return;
    }
    updatePhones();
    let people = formState.values;
    people.phones = phones;

    if (people.taxpayerRegistry) {
      const taxpayerRegistryOnlyNumbers = clearInquiryNumber(people.taxpayerRegistry);

      people.taxpayerRegistry = taxpayerRegistryOnlyNumbers;

      if (editInvestigatedInArray) {
        people.type = showPhysicalFields ? 'PF' : 'PJ';
      }
    }

    const changedToVictim =
      editInvestigatedInArray &&
      peoples.find(item => item.id === people.id).peopleType === 'investigated' &&
      people.peopleType === 'victim';

    const changedToInvestigated =
      editInvestigatedInArray &&
      peoples.find(item => item.id === people.id).peopleType === 'victim' &&
      people.peopleType === 'investigated';

    if (changedToVictim || changedToInvestigated) {
      setOpenModalConfirmChangePersonType(true);
      setIsChangeToInvestigated(changedToInvestigated);
    }

    if (!editInvestigatedInArray) {
      if (people.peopleType === 'victim') {
        delete people.peopleType;
        delete people.crimes;
        addVictim(people);
      } else if (people.peopleType === 'legalRepresentative') {
        delete people.peopleType;
        delete people.crimes;
        addRepresentative(people);
      } else {
        delete people.peopleType;
        addNewInvestigated(people);
      }
      return;
    }

    !changedToVictim &&
      !changedToInvestigated &&
      updateInvestigated(people, changedToVictim, changedToInvestigated);
  };

  const changedPersonType = () => {
    const people = formState.values;
    people.changedToVictim = !isChangeToInvestigated;
    people.changedToInvestigated = isChangeToInvestigated;
    if (!changePersonTypeJustification) {
      return toast.warn('Informe a justificativa para prosseguir');
    }
    people.justification = changePersonTypeJustification;
    updateInvestigated(people, !isChangeToInvestigated, isChangeToInvestigated);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const handleChangeShowInputPhonesToTrue = () => {
    setShowInputPhones(true);
  };

  const handleChangeShowInputPhonesToFalse = () => {
    setShowInputPhones(false);
  };

  const updatePhones = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        phones: phones,
      },
    }));
    setPhones(phones);
    setShowInputPhones(false);
  };

  const handleAddPhone = () => {
    const newPhone = formState.values.phones;
    if (newPhone == '') {
      toast.warn('Insira um telefone alternativo');
      return;
    }
    if (typeof newPhone !== 'undefined') {
      const index = phones.findIndex(item => item === newPhone);
      if (index === -1) {
        setPhones(phones => [...phones, newPhone]);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            phones: '',
          },
        }));
      } else {
        toast.warn(`Telefone ${newPhone} já adicionado`);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            phones: '',
          },
        }));
        return;
      }
    }
  };

  const handleRemovePhone = phone => {
    const index = phones.findIndex(item => item === phone);
    if (index !== -1) {
      setPhones(phones.filter((value, _index, _arr) => value !== phone));
    }
  };

  const handleEditSubtype = crime => {
    setSubtypesSelected(crime.subtypesSelected);
    setSelectedCrime(crime);
    setEditingSubtypes(true);
  };

  const handleChangeSubtype = newValue => {
    setSubtypesSelected(newValue);
  };

  useEffect(() => {
    setSubtypeOptions(selectedCrime?.subtypes);
  }, [selectedCrime]);

  useEffect(() => {
    if (formState.values.peopleType === 'investigated' && !editInvestigatedInArray && showForm) {
      debouncedGetLaws('');
      debouncedGetCrimes('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm, formState.values.peopleType, editInvestigatedInArray]);

  useEffect(() => {
    selectedLaw && getCrimes(inputSearchCrime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLaw]);

  useEffect(() => {
    if (formState.values.peopleType === 'victim') {
      setShowCrimeInput(false);
    } else {
      setShowCrimeInput(true);
    }
  }, [formState.values.peopleType]);

  useEffect(() => {
    const { taxpayerRegistry } = formState.values;
    taxpayerRegistry && clearInquiryNumber(taxpayerRegistry).length > 11
      ? setTaxpayerRegistryMask(cnpjMask)
      : setTaxpayerRegistryMask(cpfMask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values.taxpayerRegistry]);

  useEffect(() => {
    if (formState.values.address.uf !== '') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          address: {
            ...formState.values.address,
            city: cityObject ? cityObject.name.toUpperCase() : '',
          },
        },
      }));
    }
  }, [cityObject]);

  useEffect(() => {
    if (formState.values.address.uf !== '') {
      getCities();
      setCityObject(null);
    }
  }, [formState.values.address.uf]);

  useEffect(() => {
    if (formState.values.type === 'PF') {
      setShowPhysicalFields(true);
    } else {
      setShowPhysicalFields(false);
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          type: 'PJ',
        },
      }));
    }
  }, [formState.values.type]);

  useEffect(() => {
    if (listClassesAtosInfracionais.some(e => e.code === inquiryClasse)) {
      setIsInfraction(true);
      setInvestigatedForRepresentative(
        window.location.href.split('/')[window.location.href.split('/').length - 1],
      );
    }
  }, []);

  useEffect(() => {
    setGridSize(!editInvestigatedInArray && formState.values.peopleType !== 'victim' ? 4 : 6);
  }, [formState.values.peopleType, editInvestigatedInArray]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.titulo}>
              {isInfraction
                ? 'Dados Pessoais do Investigado selecionado, Vítimas e Representantes'
                : 'Dados Pessoais do Investigado selecionado e Vítimas'}
            </Typography>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {showForm ? (
                <Tooltip title="Fechar formulário" arrow placement="top">
                  <IconButton
                    color="primary"
                    onClick={handleChangeStateEditToFalse}
                    style={{ marginRight: 10 }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              ) : canEdit || (!otherStep && !isPM) ? (
                <Tooltip title="Adicionar pessoa" arrow placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => (!write ? handleOpenSnackbar() : handleAddInvestigated())}
                    style={{ marginRight: 10 }}
                  >
                    <PersonAddIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Grid>

        {showForm && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <form className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <div className={classes.containerLabelInput}>
                      {formState.values.peopleType === 'legalRepresentative' ||
                      !editInvestigatedInArray ? (
                        <InputConsensus
                          select
                          label="Tipo de pessoa"
                          name="peopleType"
                          onChange={handleChangePeopleType}
                          disabled={
                            formState.values.peopleType === 'legalRepresentative' &&
                            editInvestigatedInArray
                          }
                          value={formState.values.peopleType}
                          className={classes.inputMaterial}
                        >
                          <MenuItem value="investigated">Investigado</MenuItem>
                          <MenuItem value="victim">Vítima</MenuItem>
                          {isInfraction &&
                            (!peoples.some(people => people.peopleType === 'legalRepresentative') ||
                              editInvestigatedInArray) && (
                              <MenuItem value="legalRepresentative">Representante Legal</MenuItem>
                            )}
                        </InputConsensus>
                      ) : (
                        <InputConsensus
                          select
                          label="Tipo de pessoa"
                          name="peopleType"
                          disabled={!enableChangePersonType && editInvestigatedInArray}
                          onChange={handleChangePeopleType}
                          value={formState.values.peopleType}
                          className={classes.inputMaterial}
                        >
                          <MenuItem value="investigated">Investigado</MenuItem>
                          <MenuItem value="victim">Vítima</MenuItem>
                        </InputConsensus>
                      )}
                    </div>
                  </Grid>
                  {formState.values.peopleType === 'legalRepresentative' && (
                    <Grid item xs>
                      <div className={classes.containerLabelInput}>
                        <ComboBoxMultiSelect
                          options={peoples.filter(people => people.peopleType === 'investigated')}
                          disabled={true}
                          label="Selecione os investigados *"
                          selectedIds={[
                            peoples.find(people => people.peopleType === 'investigated').id,
                          ]}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        select
                        label="Pessoa Física/Jurídica"
                        fullWidth
                        name="type"
                        onChange={handleChange}
                        value={formState.values.type || ''}
                        className={classes.inputMaterial}
                      >
                        <MenuItem value="PF">Pessoa Física</MenuItem>
                        <MenuItem value="PJ">Pessoa Jurídica</MenuItem>
                      </InputConsensus>
                    </div>
                  </Grid>
                  {showCrimeInput && !editInvestigatedInArray && (
                    <Grid item xs>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <FormControlLabel
                          label="O investigado encontra-se preso?"
                          control={
                            <Checkbox
                              checked={formState.values.isArrested}
                              onChange={handleChange}
                              name="isArrested"
                              color="primary"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  {!showPhysicalFields ? (
                    <>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Nome Completo sem abreviações *"
                            name="name"
                            autoComplete="no"
                            onChange={handleChangeName}
                            value={formState.values.name || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.taxpayerRegistry || ''}
                            mask={cnpjMask}
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="CNPJ"
                                name="taxpayerRegistry"
                                variant="standard"
                                autoComplete="no"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChangeRg}
                            value={formState.values.rg.code || ''}
                            mask="9999999999999"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="RG *"
                                name="code"
                                variant="standard"
                                type="text"
                                autoComplete="no"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {canEdit || (!otherStep && !isPM) ? (
                          disabledBtnSds ? (
                            <Tooltip placement="top" arrow title="Buscando...">
                              <IconButton color="primary">
                                <CircularProgress size={20} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Consultar SDS pelo RG">
                              <IconButton
                                color="primary"
                                aria-label="cachedIcon"
                                onClick={requestSds}
                              >
                                <CachedIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : (
                          <Tooltip title="Consultar SDS pelo RG" arrow placement="top">
                            <IconButton
                              color="primary"
                              aria-label="cachedIcon"
                              onClick={requestSds}
                              disabled
                            >
                              <CachedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            label="Orgão Emissor"
                            select
                            name="issuingBody"
                            onChange={handleChangeRg}
                            value={formState.values.rg.issuingBody || ''}
                            className={classes.inputMaterial}
                          >
                            {listOrgaoEmissorRg().map((v, i) => (
                              <MenuItem key={i} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          </InputConsensus>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={3} md={3} lg={3} xl={3}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            id="custom-css-standard-input-emissao"
                            label="Data Emissão"
                            InputLabelProps={{ shrink: true }}
                            value={formState.values.rg.issueDate || ''}
                            onChange={handleChangeRg}
                            name="issueDate"
                            type="date"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div className={classes.containerLabelInput} class="notranslate">
                          <InputConsensus
                            className={classes.inputMaterial}
                            id="standard-select-currency-uf-ufgr"
                            select
                            label="UF RG"
                            name="uf"
                            value={formState.values.rg.uf || ''}
                            onChange={handleChangeRg}
                          >
                            {listUf().map((v, i) => (
                              <MenuItem key={i} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          </InputConsensus>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Nome Completo sem abreviações *"
                            name="name"
                            autoComplete="no"
                            onChange={handleChangeName}
                            value={formState.values.name || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Nome da Mãe"
                            name="motherName"
                            autoComplete="no"
                            onChange={handleChangeName}
                            value={formState.values.motherName || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={5} md={2} lg={2} xl={2}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChangeAddress}
                            value={formState.values.address.zipCode || ''}
                            mask="99999-999"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                className={classes.inputMaterial}
                                label="CEP"
                                name="zipCode"
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {disabledBtnCep ? (
                          <Tooltip placement="top" arrow title="Buscando...">
                            <IconButton color="primary">
                              <CircularProgress size={20} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip Tooltip title="Preencher endereço">
                            <IconButton
                              color="primary"
                              aria-label="cachedIcon"
                              onClick={requestZipCode}
                              disabled={isPM ? !canEdit : !write}
                            >
                              <CachedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Endereço"
                            name="street"
                            autoComplete="no"
                            onChange={handleChangeAddress}
                            value={formState.values.address.street || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            label="Número"
                            className={classes.inputMaterial}
                            type="number"
                            name="number"
                            autoComplete="no"
                            onChange={handleChangeAddress}
                            value={formState.values.address.number || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            name="complement"
                            label="Complemento"
                            autoComplete="no"
                            onChange={handleChangeAddress}
                            value={formState.values.address.complement || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={9} sm={4} md={5} lg={5} xl={5}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Bairro"
                            name="neighborhood"
                            autoComplete="no"
                            onChange={handleChangeAddress}
                            value={formState.values.address.neighborhood || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            id="standard-select-currency-uf-address"
                            select
                            label="UF"
                            name="uf"
                            value={formState.values.address.uf || ''}
                            onChange={handleChangeAddress}
                          >
                            {listUf().map((v, i) => (
                              <MenuItem key={i} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          </InputConsensus>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.containerLabelInput}>
                          <Autocomplete
                            className={classes.inputMaterial}
                            options={cities}
                            fullWidth
                            autoHighlight
                            onChange={handleChangeJudicialDistrict}
                            clearOnBlur={false}
                            noOptionsText="Não encontrado"
                            value={cityObject || null}
                            getOptionLabel={option => option.name || ''}
                            renderOption={option => <>{option.name}</>}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                id="standard-select-currency-uf-address-city"
                                className={classes.inputMaterial}
                                name="city"
                                label={labelCities}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            label="Naturalidade"
                            className={classes.inputMaterial}
                            name="city"
                            //autoComplete="no"
                            onChange={handleChangeplaceOfBirth}
                            value={formState.values.placeOfBirth.city || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            id="standard-select-currency-uf-placeOfBirth"
                            select
                            label="UF Naturalidade"
                            name="uf"
                            value={formState.values.placeOfBirth.uf || ''}
                            onChange={handleChangeplaceOfBirth}
                          >
                            {listUf().map((v, i) => (
                              <MenuItem key={i} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          </InputConsensus>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            id="custom-css-standard-input-date-birth"
                            label="Nascimento"
                            InputLabelProps={{ shrink: true }}
                            value={formState.values.birthDate || ''}
                            onChange={handleChange}
                            name="birthDate"
                            type="date"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            className={classes.inputMaterial}
                            label="Nacionalidade"
                            name="nationality"
                            onChange={handleChange}
                            value={formState.values.nationality || ''}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            select
                            className={classes.inputMaterial}
                            label="Estado Civil"
                            name="maritalStatus"
                            onChange={handleChangeMaritalStatus}
                            value={formState.values.maritalStatus || ''}
                          >
                            {listEstadoCivil().map((v, i) => (
                              <MenuItem value={v.value} key={i}>
                                {v.name}
                              </MenuItem>
                            ))}
                          </InputConsensus>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.pis || ''}
                            mask="999.99999.99-9"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="PIS/PASEP"
                                name="pis"
                                variant="standard"
                                autoComplete="no"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.taxpayerRegistry || ''}
                            mask={!editInvestigatedInArray ? taxpayerRegistryMask : cpfMask}
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label={'CPF'}
                                name="taxpayerRegistry"
                                variant="standard"
                                autoComplete="no"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.phone || ''}
                            mask="(99)99999-9999"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="Telefone/Whatsapp"
                                name="phone"
                                type="phone"
                                variant="standard"
                                autoComplete="no"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <AlternativePhones
                          phones={phones}
                          editInvestigatedInArray={editInvestigatedInArray}
                          handleChangeShowInputPhonesToFalse={handleChangeShowInputPhonesToFalse}
                          handleRemovePhone={handleRemovePhone}
                          handleAddPhone={handleAddPhone}
                          handleChangeShowInputPhonesToTrue={handleChangeShowInputPhonesToTrue}
                          showInputPhones={showInputPhones}
                          handleChange={handleChange}
                          formState={formState}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.containerLabelInput}>
                          <InputConsensus
                            label="E-mail"
                            className={classes.inputMaterial}
                            type="email"
                            name="email"
                            autoComplete="no"
                            onChange={handleChange}
                            value={formState.values.email || ''}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                  {!editInvestigatedInArray && formState.values.peopleType === 'investigated' && (
                    <>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className={classes.containerLabelInput}>
                          <Autocomplete
                            onInputChange={(event, newInputValue) => updateValueLaws(newInputValue)}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            filterOptions={x => x}
                            value={selectedLaw}
                            fullWidth
                            noOptionsText="Nada foi encontrado"
                            loadingText="Buscando..."
                            loading={loadingLaws}
                            clearOnBlur={false}
                            options={optionsLaw}
                            onChange={handleChangeLaw}
                            renderOption={option => (
                              <>
                                {option.name} - {option.number}
                              </>
                            )}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                label="Lei"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingLaws ? (
                                        <CircularProgress color="primary" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            ListboxProps={{
                              onScroll: handleScrollLaw,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className={classes.containerLabelInput}>
                          <Autocomplete
                            //value={null}
                            key={keyCrime}
                            onInputChange={(event, newInputValue) =>
                              updateValueCrimes(newInputValue)
                            }
                            getOptionSelected={(option, value) =>
                              option.taxonomy === value.taxonomy
                            }
                            getOptionLabel={option => option.taxonomy}
                            filterOptions={x => x}
                            fullWidth
                            noOptionsText="Nada foi encontrado"
                            loadingText="Buscando..."
                            loading={loadingCrimes}
                            clearOnBlur={false}
                            options={crimesCometidos}
                            onChange={handleChangeCrimeAutocomplete}
                            value={selectedCrime}
                            disabled={editingSubtypes}
                            renderOption={option => (
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography variant="body1">
                                      {option.taxonomy &&
                                        `Nome ${option.taxonomy || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.lawName && `Lei ${option.lawName || 'não informada'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.lawNumber &&
                                        `Lei n° ${option.lawNumber || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.article &&
                                        `Artigo ${option.article || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.paragraph &&
                                        `Parágrafo n° ${option.paragraph || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.item && `Item ${option.item || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.point && `Ponto ${option.point || 'não informado'}`}
                                    </Typography>
                                    <Typography variant="body1">
                                      {option?.subject?.hierarchy &&
                                        `Hierarquia ${option.lawNumber || 'não informada'}`}
                                    </Typography>
                                  </React.Fragment>
                                }
                              >
                                <Typography>
                                  {option.taxonomy} - Lei:{' '}
                                  {option.lawNumber ? option.lawNumber : 'não informada'} - Artigo:{' '}
                                  {option.article ? option.article : 'não informado'}{' '}
                                  {option.paragraph ? '-' : ''}{' '}
                                  {option.paragraph ? option.paragraph : ''}{' '}
                                  {option.item ? '-' : ''} {option.item ? option.item : ''}{' '}
                                  {option.point ? '-' : ''} {option.point ? option.point : ''}
                                </Typography>
                              </HtmlTooltip>
                            )}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                label={`${isInfraction ? 'Ato Infracional' : 'Crime'} cometido *`}
                                fullWidth
                                onClick={() => {
                                  setSubtypeOptions(null);
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingCrimes ? (
                                        <CircularProgress color="primary" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            ListboxProps={{
                              onScroll: handleScrollCrimes,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        {subtypeOptions?.length > 0 && (
                          <div className={classes.containerLabelInput}>
                            <ComboBoxMultiSelect
                              options={subtypeOptions}
                              label="Selecione os subtipos"
                              selectedIds={subtypesSelected?.map(subtype => subtype.id)}
                              onChange={handleChangeSubtype}
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          paddingRight: 15,
                        }}
                      >
                        <ButtonConsensus
                          title={editingSubtypes ? 'Alterar' : 'Adicionar'}
                          backgroundColor={colors.primary}
                          hovercolor={colors.primary}
                          size="small"
                          onClick={handleChangeCrime}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ paddingLeft: 5 }}>
                        <div className={classes.addCrimeContainer}>
                          {isInfraction ? 'Ato(s) Infracional(is)' : 'Crime(s)'}:
                          {formState.values.crimes.length > 0
                            ? formState.values.crimes.map(crime => (
                                <div key={crime.id} className={classes.textoCrimeContainer}>
                                  <Typography>
                                    {crime.taxonomy} - Lei:{' '}
                                    {crime.lawNumber ? crime.lawNumber : 'não informada'} - Artigo:{' '}
                                    {crime.article ? crime.article : 'não informado'}{' '}
                                    {crime.paragraph ? '-' : ''}{' '}
                                    {crime.paragraph ? crime.paragraph : ''} {crime.item ? '-' : ''}{' '}
                                    {crime.item ? crime.item : ''} {crime.point ? '-' : ''}{' '}
                                    {crime.point ? crime.point : ''}{' '}
                                    {crime.subtypesSelected?.length
                                      ? `- Subtipos: ${crime.subtypesSelected
                                          .map(obj => obj.name)
                                          .join(', ')}`
                                      : ''}
                                  </Typography>
                                  <div className={classes.iconContainer}>
                                    {crime.subtypes?.length > 0 && (
                                      <EditIcon
                                        fontSize="small"
                                        onClick={() => handleEditSubtype(crime)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    )}
                                    <DeleteIcon
                                      fontSize="small"
                                      onClick={event => handleRemoveCrime(event, crime.id)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                </div>
                              ))
                            : ''}
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>

            {(canEdit || (!otherStep && !isPM)) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 15,
                }}
              >
                <ButtonConsensus
                  title={editInvestigatedInArray ? 'Alterar Pessoa' : 'Adicionar Pessoa'}
                  backgroundColor={colors.primary}
                  hovercolor={colors.primary}
                  size="small"
                  onClick={event => (!write ? handleOpenSnackbar() : handleSubmit(event))}
                  disabled={disabledSubmitButton}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box p={1.5}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                {peoples.length < 1 && (
                  <caption style={{ textAlign: 'center' }}>Não há pessoas adicionadas</caption>
                )}
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">RG</TableCell>
                    {!visualizeOnly && (
                      <TableCell width={180} align="center">
                        Ações
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {peoples.map((investigated, index) => (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row" align="center">
                        {clearSpecialCharacterName(investigated.name)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {investigated.peopleType === 'investigated'
                          ? 'Investigado'
                          : investigated.peopleType === 'victim'
                          ? 'Vítima'
                          : 'Representante Legal'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {investigated.taxpayerRegistry
                          ? formatedTaxpayerRegister(investigated.taxpayerRegistry)
                          : 'não informado'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {investigated?.rg?.code || 'Não informado'}
                      </TableCell>
                      {!visualizeOnly && (
                        <TableCell
                          align="center"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box style={{ marginRight: 10 }}>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => handleUpdateInvestigated(investigated)}
                            >
                              {canEdit || (!otherStep && !isPM) ? (
                                <Tooltip title="Dados da pessoa" arrow placement="top">
                                  <VisibilityIcon />
                                </Tooltip>
                              ) : (
                                <EditIcon />
                              )}
                            </IconButton>
                          </Box>
                          {canEdit || (!otherStep && !isPM) ? (
                            investigated.peopleType !== 'investigated' ? (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={event =>
                                  !write
                                    ? handleOpenSnackbar()
                                    : handleDeleteInvestigated(event, investigated, index)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <IconButton color="primary" size="small" disabled>
                                <DeleteIcon />
                              </IconButton>
                            )
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.titulo}>Demais Investigados</Typography>
          {otherInvestigateds.length === 0 ? (
            <Box mt={1}>
              <Typography variant="h6" className={classes.infoInvestigated}>
                Não há outros investigados
              </Typography>
            </Box>
          ) : (
            <Box p={1.5}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">CPF/CNPJ</TableCell>
                      <TableCell align="center">RG</TableCell>
                      {!visualizeOnly && (
                        <TableCell width={180} align="center">
                          Ações
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherInvestigateds.map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row" align="center">
                          {clearSpecialCharacterName(item.investigated.name)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item.investigated.taxpayerRegistry
                            ? formatedTaxpayerRegister(item.investigated.taxpayerRegistry)
                            : 'não informado'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item.investigated?.rg?.code || 'Não informado'}
                        </TableCell>
                        {!visualizeOnly && (
                          <TableCell align="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              width="100%"
                            >
                              <Box mr={1}>
                                {(!isPM || canEdit) && (
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() =>
                                      redirectToInquiryIdStep({ id: item.id, step: item.step })
                                    }
                                  >
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={`Ir para a tela de detalhe do investigado ${item.investigated.name}`}
                                    >
                                      <VisibilityIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              </Box>
                              {canEdit || (!otherStep && !isPM) ? (
                                item.investigated.peopleType !== 'investigated' ? (
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={event =>
                                      handleDeleteInvestigated(event, item.investigated, index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton color="primary" size="small" disabled>
                                    <DeleteIcon />
                                  </IconButton>
                                )
                              ) : (
                                <></>
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Grid>
      </Grid>
      {openModalConfirm && (
        <ModalConfirm
          open={openModalConfirm}
          onClose={handleCloseModalConfirm}
          person={selectedInvestigated}
          onClick={deleteVictim}
        />
      )}
      {openModalConfirmChangePersonType && (
        <ModalConfirmChangePersonType
          open={openModalConfirmChangePersonType}
          onClose={() => setOpenModalConfirmChangePersonType(false)}
          onSave={changedPersonType}
          justification={changePersonTypeJustification}
          setJustification={setChangePersonTypeJustification}
          isChangeToInvestigated={isChangeToInvestigated}
        />
      )}
    </>
  );
};

export default FormPersonalData;
