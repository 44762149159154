import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Button, Navigation } from 'components';
import { navigationConfig } from './navigationConfig';
import NewNavBarContent from './NewNavBarContent';
import { setUrlCallbackLogin } from 'utils/setUrlCallbackLogin';
import { OLD_URLS } from 'constants/old-urls';
import { CUSTOMER_CONFIG } from 'constants/customerConfig';
import { useSelector } from 'react-redux';
import ModalVersion from '../ModalVersion';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    //paddingLeft: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    //marginTop: theme.spacing(2)
  },
  footerContainer: {
    position: 'flex',
    bottom: 0,
    width: '100%',
  },
  versionButton: {
    position: 'flex',
    bottom: 0,
    padding: theme.spacing(2),
  },
  versionText: {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.05px',
  },
}));

const helperFilter = (item, href) => item.href !== href;

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const allCustomerConfigs = useSelector(state => state.customerConfig);
  const {
    INQUIRY_IMPORT,
    INQUIRY_MANUAL_REGISTRATION,
    DISPLAY_TRIAGE_STEP,
    DISPLAY_DISTRIBUTION_STEP,
    VIEW_PCPE_INTEGRATION_REPORT,
    VIEW_PROCEDURE_AUDIT_REPORT,
    ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED,
    ENABLE_AUDIT_LOG_VIEW,
    ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION,
    ENABLE_PARTNER_ASSOCIATION_REGISTRATION,
    ENABLE_INQUIRIES_MIGRATION_REPORT,
    ENABLE_SEMA_SERVER_ACTIVITIES_VIEW
  } = CUSTOMER_CONFIG;

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const [frontVersion, setFrontVersion] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const validationNavigationConfig = useMemo(() => {
    let navigation = [...navigationConfig(allCustomerConfigs)];
    const registrationModuleIndex = navigation[0].pages.findIndex(
      item => item.title === 'Cadastros',
    );

    const registrationModuleChildren =
      navigation[0].pages.find(item => item.title === 'Cadastros')?.children || [];

    let newChildrensRegistrationModule = registrationModuleChildren;

    if (!allCustomerConfigs[INQUIRY_MANUAL_REGISTRATION]) {
      newChildrensRegistrationModule = newChildrensRegistrationModule.filter(item =>
        helperFilter(item, '/inquerito/cadastro'),
      );
    }

    if (!allCustomerConfigs[INQUIRY_IMPORT]) {
      newChildrensRegistrationModule = newChildrensRegistrationModule.filter(item =>
        helperFilter(item, '/inquerito/importar'),
      );
    }

    if (!allCustomerConfigs[ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION]) {
      newChildrensRegistrationModule = newChildrensRegistrationModule.filter(item =>
        helperFilter(item, '/cadastro-sema-cema'),
      );
    }

    if (!allCustomerConfigs[ENABLE_PARTNER_ASSOCIATION_REGISTRATION]) {
      newChildrensRegistrationModule = newChildrensRegistrationModule.filter(item =>
        helperFilter(item, '/cadastro-parceiros'),
      );
    }

    const stepsModuleIndex = navigation[0].pages.findIndex(item => item.title === 'Fases');

    let stepsModuleChildren =
      navigation[0].pages.find(item => item.title === 'Fases')?.children || [];

    if (!allCustomerConfigs[DISPLAY_TRIAGE_STEP]) {
      stepsModuleChildren = stepsModuleChildren.filter(item =>
        helperFilter(item, '/triagem-nao-persecucao'),
      );
    }
    if (!allCustomerConfigs[DISPLAY_DISTRIBUTION_STEP]) {
      stepsModuleChildren = stepsModuleChildren.filter(item =>
        helperFilter(item, '/pesquisa-e-distribuicao'),
      );
    }

    const reportsModuleIndex = navigation[0].pages.findIndex(item => item.title === 'Relatórios');
    let reportsModuleChildren =
      navigation[0].pages.find(item => item.title === 'Relatórios')?.children || [];

    if (!allCustomerConfigs[VIEW_PCPE_INTEGRATION_REPORT]) {
      reportsModuleChildren = reportsModuleChildren.filter(item =>
        helperFilter(item, '/relatorio/integracao-pcpe'),
      );
    }

    if (!allCustomerConfigs[VIEW_PROCEDURE_AUDIT_REPORT]) {
      reportsModuleChildren = reportsModuleChildren.filter(item =>
        helperFilter(item, '/relatorio/auditoria-procedimento'),
      );
    }

    if (!permissionsReducer?.inquiriesMigrationReport?.read) {
      reportsModuleChildren = reportsModuleChildren.filter(item =>
        helperFilter(item, '/relatorio/inqueritos-migrados'),
      );
    }

    const moduleIndex = navigation[0].pages.findIndex(item => item.title === 'Módulos');
    let moduleChildren = navigation[0].pages.find(item => item.title === 'Módulos')?.children || [];
    if (!permissionsReducer?.prosecutorLifetime?.read) {
      moduleChildren = moduleChildren.filter(item => helperFilter(item, '/vitaliciamento'));
    }

    if (!allCustomerConfigs[ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED]) {
      moduleChildren = moduleChildren.filter(item =>
        helperFilter(item, '/sincronizacao-manual-de-investigados'),
      );
    }

    if (!allCustomerConfigs[ENABLE_AUDIT_LOG_VIEW]) {
      moduleChildren = moduleChildren.filter(item => helperFilter(item, '/log-de-auditoria'));
    }

    if (!allCustomerConfigs[ENABLE_SEMA_SERVER_ACTIVITIES_VIEW]) {
      moduleChildren = moduleChildren.filter(item => helperFilter(item, '/atividades-servidores-sema'));
    }

    navigation[0].pages[registrationModuleIndex].children = newChildrensRegistrationModule;
    navigation[0].pages[stepsModuleIndex].children = stepsModuleChildren;
    navigation[0].pages[reportsModuleIndex].children = reportsModuleChildren;
    navigation[0].pages[moduleIndex].children = moduleChildren;

    return navigation;
  }, [
    allCustomerConfigs,
    INQUIRY_MANUAL_REGISTRATION,
    INQUIRY_IMPORT,
    ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION,
    ENABLE_PARTNER_ASSOCIATION_REGISTRATION,
    ENABLE_INQUIRIES_MIGRATION_REPORT,
    DISPLAY_TRIAGE_STEP,
    DISPLAY_DISTRIBUTION_STEP,
    VIEW_PCPE_INTEGRATION_REPORT,
    VIEW_PROCEDURE_AUDIT_REPORT,
    permissionsReducer?.prosecutorLifetime?.read,
    ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED,
  ]);

  const classes = useStyles();
  const router = useRouter();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getFrontVersion = () => {
    try {
      const packageData = require('../../../../../package.json');
      return packageData.version;
    } catch (error) {
      toast.error('Erro ao buscar a versão do front');
      return null;
    }
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(() => {
    const url = setUrlCallbackLogin();
    localStorage.setItem(OLD_URLS.user_and_prosecutor, url);
    setFrontVersion(getFrontVersion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {validationNavigationConfig.map(list => (
          <Navigation component="div" key={list.title} pages={list.pages} title={list.title} />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
            <div className={classes.footerContainer}>
              <Button className={classes.versionButton} onClick={handleOpenModal}>
                <Typography style={{ color: '#C1C6C9' }} className={classes.versionText}>
                  Versão: {frontVersion}
                </Typography>
              </Button>
            </div>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          <NewNavBarContent />
          <div className={classes.footerContainer}>
            <Button className={classes.versionButton} onClick={handleOpenModal}>
              <Typography style={{ color: '#FFFFFF' }} className={classes.versionText}>
                Versão: {frontVersion}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Hidden>
      {isModalOpen && (
        <ModalVersion frontVersion={frontVersion} open={isModalOpen} onClose={handleCloseModal} />
      )}
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
