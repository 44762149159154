import { currencyFormat, getOnlyNumbers } from 'utils/currency';

const pecuniaryParser = pecuniaryPenalties => {
  const newArrayPecuniary = [];
  pecuniaryPenalties.forEach(pecuniaryApi => {
    const pecuniaryValue = Number(getOnlyNumbers(`${pecuniaryApi.value}`));
    const formatedValue = currencyFormat(pecuniaryValue / 100);

    const newPecuniary = {
      ...pecuniaryApi,
      paymentDeadline: pecuniaryApi?.paymentDeadline?.substring(0, 10) || '',
      value: formatedValue,
      penalty: 'Pecúnia',
      parcels: pecuniaryApi?.installments?.map(i => ({
        ...i,
        value: String(i?.value),
        date: i.finalDate,
        initialDate: i.initialDate,
        minimumValue: String(i?.minimumValue),
        maximumValue: String(i?.maximumValue),
      })),
      project: pecuniaryApi?.project,
      installmentType: pecuniaryApi?.installmentType,
      totalValue: pecuniaryValue,
    };

    newArrayPecuniary.push(newPecuniary);
  });
  return newArrayPecuniary;
};

export default pecuniaryParser;
