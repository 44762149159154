import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, Box, Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useDispatch, useSelector } from 'react-redux';
import { Form, StatusIntimation } from './components';
import Skeleton from '@material-ui/lab/Skeleton';
import ModalHistoryIntimation from 'components/ModalHistoryIntimation';
import { ModalAddFile, ModalCancel, ModalListFiles } from './components';
import ModalHearing from './components/ModalHearing';
import LockIcon from '@material-ui/icons/Lock';
import { changeNameStep } from 'utils/changeNameStep';
import { toast } from 'react-toastify';
import { setRequestIntimationsReducer } from 'actions';
import intimationService from 'services/intimation/intimationService';

const useStyles = makeStyles(theme => ({
  root: {
    background: colors.navBar,
    minHeight: '30vh',
    borderRadius: 20,
    padding: theme.spacing(2),
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
  },
  containerCards: {
    padding: theme.spacing(2, 0, 2, 0),
    height: '100%',
  },
  containerRight: {
    backgroundColor: '#ededed',
    height: '100%',
    padding: theme.spacing(1),
  },
  containerBtnCanel: {
    backgroundColor: '#ededed',
    height: '100%',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const IntimationNew = props => {
  const { inquiry, step = '' } = props;

  const dispatch = useDispatch();

  const classes = useStyles();
  const customerConfig = useSelector(state => state.customerConfig);

  const permissionsReducer = useSelector(state => state.logedUser.permissions);
  const { write, read } = permissionsReducer.inquiriesIntimacao;

  const [isResult, setIsResult] = useState(false);
  const [intimations, setIntimations] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const [openModalListFile, setOpenModalListFile] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openHearingModal, setOpenHearingModal] = useState(false);
  const [disabledIntimation, setDisabledIntimation] = useState(false);
  const [disabledAddFileIntimation, setDisabledAddFileIntimation] = useState(false);
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseModalCancel = () => {
    setOpenModalCancel(false);
  };

  const handleCloseModalInPerson = () => {
    setOpenHearingModal(false);
  };

  const handleCloseModalListFile = () => {
    setOpenModalListFile(false);
  };

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const handleCloseModalHistoryIntimation = () => {
    setOpen(false);
  };

  const handleOpenModalHistoryIntimation = () => {
    setOpen(true);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) getIntimations();

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyIntimation = intimations => {
    if (intimations.length > 0) {
      if (step !== 'INTIMACAO') {
        setDisabledIntimation(true);
        setDisabledAddFileIntimation(true);
        return;
      }

      if (intimations[0].status === 'CANCELED') {
        setDisabledIntimation(false);
        setDisabledAddFileIntimation(true);
      } else if (intimations[0].status === 'FAILED') {
        setDisabledIntimation(false);
        setDisabledAddFileIntimation(false);
      } else if (intimations[0].status === 'AWAITING_RESPONSE') {
        setDisabledIntimation(true);
        setDisabledAddFileIntimation(false);
      } else if (intimations[0].status === 'SUCCESS') {
        setDisabledIntimation(false);
        setDisabledAddFileIntimation(true);
      } else {
        setDisabledIntimation(false);
      }
    } else {
      setDisabledIntimation(false);
      setDisabledAddFileIntimation(true);
    }
  };

  const getIntimations = () => {
    let params = {
      inquiryInvestigatedId: inquiry.id,
    };

    intimationService.getIntimations(params).then(response => {
      setIntimations(response.data.data);
      verifyIntimation(response.data.data);
      setIsResult(true);
    });
  };

  const intimationHearing = formState => {
    let newParams = {
      hearingDate: params.hearingDate,
      inquiryInvestigatedId: inquiry.id,
      isInPerson: params.isInPerson,
      hearingPlace: params.isInPerson ? formState.hearingPlace : '',
      observation: formState.observation,
      publicDefender: formState.publicDefender,
      prosecutor: formState.prosecutor,
      prosecutorOffice: formState.prosecutorOffice,
    };

    if (params.isInPerson && !formState.hearingPlace) {
      setLoading(false);
      return toast.warn('Local da audiência obrigatório');
    }

    intimationService
      .createIntimationToHearing(newParams)
      .then(() => {
        setOpenHearingModal(false);
        setParams(null);
        dispatch(setRequestIntimationsReducer(true));
        toast.success('Intimação realizada com sucesso');
        setTimeout(() => {
          getIntimations();
        }, 1000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelIntimation = () => {
    intimationService.cancelIntimation(intimations[0].id).then(() => {
      dispatch(setRequestIntimationsReducer(true));
      toast.success('Intimação cancelada com sucesso!');
      getIntimations();
    });
  };

  const handleOpenModalConfirmCancel = () => {
    setOpenModalCancel(true);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const copyToClipboard = url => {
    navigator.clipboard.writeText(url);
    toast.success('Link para a sala de reunião virtual copiado com sucesso!');
  };

  return (
    <>
      {isResult ? (
        <Card className={classes.root}>
          <Box className={classes.containerTitle}>
            <Typography variant="h4" className={classes.title}>
              Intimação
            </Typography>
            <Tooltip
              title={!intimations.length ? 'Não há histórico' : 'Ver histórico'}
              placement="top"
              arrow
            >
              <IconButton
                style={{ backgroundColor: '#ededed' }}
                size="small"
                onClick={handleOpenModalHistoryIntimation}
              >
                <TimelineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container spacing={2} className={classes.containerCards}>
            <Grid
              item
              xl={disabledIntimation || step !== 'INTIMACAO' ? 6 : 7}
              lg={disabledIntimation || step !== 'INTIMACAO' ? 6 : 7}
              md={disabledIntimation || step !== 'INTIMACAO' ? 6 : 7}
              sm={12}
              xs={12}
            >
              {!write ? (
                <Paper elevation={8} className={classes.containerBtnCanel}>
                  <Typography>
                    <LockIcon />
                  </Typography>
                  <Typography variant="h5">Você não tem permissão para intimação</Typography>
                </Paper>
              ) : step !== 'INTIMACAO' ? (
                <Paper elevation={8} className={classes.containerBtnCanel}>
                  <Typography variant="h5">
                    Ações indisponívels em {changeNameStep(step, customerConfig)}
                  </Typography>
                </Paper>
              ) : disabledIntimation ? (
                <Paper elevation={8} className={classes.containerBtnCanel}>
                  {intimations[0].status === 'SUCCESS' ? (
                    <Typography variant="h5">Intimação realizada com sucesso!</Typography>
                  ) : (
                    <Box sx={{ display: 'flex' }}>
                      <Button
                        onClick={() =>
                          write ? handleOpenModalConfirmCancel() : handleOpenSnackbar()
                        }
                        variant="contained"
                        color="secondary"
                      >
                        Cancelar intimação
                      </Button>

                      <Box sx={{ marginLeft: 10 }}>
                        <Button
                          onClick={() => copyToClipboard(intimations[0]?.hearing?.videoCallUrl)}
                          variant="contained"
                          color="primary"
                        >
                          Copiar link
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Paper>
              ) : (
                <Form
                  params={params}
                  setParams={setParams}
                  prosecutor={inquiry?.inquiry?.prosecutor}
                  setOpenHearingModal={setOpenHearingModal}
                />
              )}
            </Grid>
            <Grid
              item
              xl={disabledIntimation || step !== 'INTIMACAO' ? 6 : 5}
              lg={disabledIntimation || step !== 'INTIMACAO' ? 6 : 5}
              md={disabledIntimation || step !== 'INTIMACAO' ? 6 : 5}
              sm={12}
              xs={12}
            >
              <StatusIntimation
                intimations={intimations}
                setOpenModalAddFile={setOpenModalAddFile}
                setOpenModalListFile={setOpenModalListFile}
                write={write}
                read={read}
                handleOpenSnackbar={handleOpenSnackbar}
                disabledAddFileIntimation={disabledAddFileIntimation}
              />
            </Grid>
          </Grid>
          {intimations.length > 0 && (
            <>
              <ModalHistoryIntimation
                open={open}
                intimations={intimations}
                onClose={handleCloseModalHistoryIntimation}
              />
              {openModalAddFile && (
                <ModalAddFile
                  open={openModalAddFile}
                  onClose={handleCloseModalAddFile}
                  intimation={intimations[0]}
                />
              )}
            </>
          )}
          {openModalListFile && (
            <ModalListFiles
              open={openModalListFile}
              onClose={handleCloseModalListFile}
              intimation={intimations[0]}
            />
          )}
          {params && (
            <ModalHearing
              open={openHearingModal}
              isInPerson={params.isInPerson}
              onClose={handleCloseModalInPerson}
              intimationHearing={intimationHearing}
              inquiry={inquiry}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          <ModalCancel
            open={openModalCancel}
            onClose={handleCloseModalCancel}
            cancellIntimation={cancelIntimation}
          />
        </Card>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={200}
          style={{ borderRadius: 20 }}
        />
      )}
    </>
  );
};

export default IntimationNew;
