import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Dialog, Typography } from '@material-ui/core';
import { ButtonConsensus, HeaderModal } from 'components';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import { colors } from 'utils/colorsSteps';
import movementTaskService from 'services/movement-task/movementTaskService';
import ButtonLoginGoogleApi from 'views/CalendarProsecutor/LoginGoogleApi/components/ButtonLoginGoogleApi/ButtonLoginGoogleApi';
import googleApiService from 'services/google-api/googleApiService';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  containerGrid: {
    width: '100%',
  },
}));

const ModalDocsEdit = props => {
  const classes = useStyles();

  const {
    policeInquiryNumber,
    onClose,
    open,
    files,
    setFiles,
    setUploadObject,
    editDocumentId,
  } = props;

  const [loading, setLoading] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [fileName] = useState(() => {
    const manualMadeFiles = files.filter(item =>
      item.name?.includes('Documento manual da Movimentação'),
    );
    const manualFilesNumbers = manualMadeFiles.map(manualFile =>
      manualFile.name.replace(/\D/g, '') ? Number(manualFile.name.replace(/\D/g, '')) : 0
    );
    let numberOfNewFile = -1
    for(let i = 0; numberOfNewFile < 0; i++) {
      if (!manualFilesNumbers.includes(i)) {
        numberOfNewFile = i;
      }
    }
    return !numberOfNewFile
        ? 'Documento manual da Movimentação.pdf'
        : `Documento manual da Movimentação (${numberOfNewFile}).pdf`; 
  })

  useEffect(() => {
    setLoading(true);
    googleApiService
      .getTokenStatusService()
      .then(async ({ data }) => {
        if (data.isAuthenticated) {
          if (editDocumentId) {
            setDocumentId(editDocumentId);
            setLoading(false);
          } else {
            movementTaskService.createNewGoogleDoc(fileName).then(response => {
              setDocumentId(response.data.documentId);
              setLoading(false);
            });
          }
        }
      }).catch(e => {
        toast.error('Erro ao buscar login do usuário')
        setLoading(false);
      })
  }, []);

  const handleOk = () => {
    if (!editDocumentId) {
      const newFile = {
        name: fileName,
        googleDocumentId: documentId,
        editDocument: false,
      };
      setFiles(files => [...files, newFile]);
      setUploadObject(files => [...files, newFile]);
    } else {
      const fileToEditIndex = files.findIndex(file => file.googleDocumentId === editDocumentId);
      if (fileToEditIndex !== -1) {
        const newFiles = [...files];
        newFiles[fileToEditIndex] = {
          ...newFiles[fileToEditIndex],
          editDocument: true,
        };

        setFiles(newFiles);
        setUploadObject(newFiles);
      }
    }
    onClose();
  };
      
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="responsive-dialog-title"
      className={classes.root}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <HeaderModal colorIconTitle="#fff" backgroundColor={colors.navBar} onClose={onClose}>
        Editar inquérito {policeInquiryNumber}
      </HeaderModal>
      <DialogContent dividers>
        {!loading ? (
          <div className={classes.container}>
            <Grid container spacing={1} className={classes.containerGrid}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {documentId ? (
                  <iframe
                    src={
                      documentId &&
                      `https://docs.google.com/document/d/${documentId}/edit?usp=sharing`
                    }
                    width="100%"
                    height="600px"
                    allow="autoplay"
                    title="show file"
                  />
                ) : (
                  <>
                    <Typography>Para gerar um documento manual, é necessário fazer login com a sua conta do Google</Typography>
                    <ButtonLoginGoogleApi />
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <CircularProgress color="primary" size={20} />
        )}
      </DialogContent>
      {documentId && (
        <DialogActions>
          <ButtonConsensus title="OK" onClick={handleOk} />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalDocsEdit;
