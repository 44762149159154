import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Box,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { InputConsensus, KeyboardDatePicker, CurrencyTextField, ButtonConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { getOnlyNumbers } from 'utils/currency';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CompensationToVictims = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    victims,
    ressarciment,
    setRessarciment,
  } = props;

  const classes = useStyles();
  const { isMPDFT } = useCustomerConfig();

  const [row, setRow] = useState(null);
  const [key, setKey] = useState(false);

  const initialValues = {
    deliveryDeadline: '',
    dateVictimCanReceive: '',
    estimatedValue: 0,
    description: '',
    penalty: isMPDFT ? 'Reparação à vítima' : 'Ressarcimento',
    victim: null,
    notification: {
      whatsapp: false,
      letter: false,
      email: false,
    },
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values:
        selectedIndex !== null
          ? {
              ...ressarciment[selectedIndex.index],
              estimatedValue: ressarciment[selectedIndex.index].estimatedValue / 100,
            }
          : { ...initialValues },
    }));
  }, [selectedIndex]);

  const handleChangeCurrency = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        estimatedValue: getOnlyNumbers(event.target.value),
      },
    }));
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChangeNotification = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        notification: {
          ...formState.values.notification,
          [event.target.name]:
            event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        },
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row },
      }));
      setKey(!key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const handleSubmit = event => {
    event.preventDefault();

    const {
      victim,
      deliveryDeadline,
      dateVictimCanReceive,
      description,
      estimatedValue,
    } = formState.values;
    const { email, letter, whatsapp } = formState.values.notification;

    if (
      !victim ||
      description === '' ||
      !estimatedValue ||
      estimatedValue === 0 ||
      estimatedValue == '0'
    ) {
      toast.warn('Todos os campos são obrigatórios com exceção das datas');
      return;
    }

    if (!email && !letter && !whatsapp) {
      toast.warn('Selecione uma forma de notificação');
      return;
    }

    if (selectedIndex !== null) {
      let newArray = [...ressarciment];
      newArray[selectedIndex.index] = formState.values;
      setRessarciment(newArray);
    } else {
      if (
        deliveryDeadline &&
        dateVictimCanReceive &&
        ressarciment.find(
          value =>
            value.deliveryDeadline === deliveryDeadline &&
            value.dateVictimCanReceive === dateVictimCanReceive,
        )
      ) {
        toast.warn('Datas já inseridas');
        return;
      }

      setRessarciment([...ressarciment, formState.values]);
    }

    handleClearFormStat();
  };

  const handleToRead = () => {
    handleClearFormStat();
  };

  const handleChangeVictim = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        victim: newValue,
      },
    }));
    setKey(!key);
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Autocomplete
              options={victims}
              fullWidth
              autoHighlight
              onChange={handleChangeVictim}
              noOptionsText="Não encontrado"
              value={formState.values.victim}
              getOptionLabel={option => option.name}
              renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
              renderInput={params => (
                <InputConsensus
                  {...params}
                  name="victimId"
                  label="Vítima"
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              label="Valor*"
              name="estimatedValue"
              onChange={handleChangeCurrency}
              value={formState.values.estimatedValue || 0}
              fullWidth
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="deliveryDeadline"
              label={isMPDFT ? 'Data de Pagamento' : 'Agenda do investigado'}
              value={formState.values.deliveryDeadline || null}
              minDate={!selectedIndex ? new Date() : undefined}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    deliveryDeadline: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="dateVictimCanReceive"
              label={isMPDFT ? 'Data de Recebimento pela Vítima' : 'Agenda da vítima'}
              value={formState.values.dateVictimCanReceive || null}
              minDate={formState.values.deliveryDeadline}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    dateVictimCanReceive: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box style={{ marginTop: 5, marginBottom: 5 }}>
              <Typography variant="h5">NOTIFICAR A VÍTIMA</Typography>
            </Box>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder={`Informar o valor ou descrever como será ${
                isMPDFT ? 'realizada a reparação à vítima' : 'realizado o ressarcimento'
              }`}
              onChange={handleChange}
              name="description"
              value={formState.values.description || ''}
            />
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
              <Box className={classes.containerCheck}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.notification.email}
                      onChange={handleChangeNotification}
                      name="email"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="E-mail"
                />
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
              <Box className={classes.containerCheck}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.notification.letter}
                      onChange={handleChangeNotification}
                      name="letter"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Carta Registrada"
                />
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
              <Box className={classes.containerCheck}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.notification.whatsapp}
                      onChange={handleChangeNotification}
                      name="whatsapp"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Whatsapp"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CompensationToVictims;
