import React, { useState } from 'react';
import PropTypes from 'prop-types';
import inquiryService from 'services/inquiries/inquiryService';
import ButtonConsensus from 'components/ButtonConsensus';
import colorsSteps from 'utils/colorsSteps';
import ModalConfirm from 'components/ModalConfirm';
import { Tooltip, Typography } from '@material-ui/core';
import history from 'utils/history';
import { toast } from 'react-toastify';

const ButtonArquivar = props => {
  const { inquiry, write, withoutTooltipMessage } = props;

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loadingArquivar, setLoadingArquivar] = useState(false);

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const changeInquiryToArquivar = () => {
    const params = {
      step: 'ARQUIVAR',
    };

    setLoadingArquivar(true);

    inquiryService
      .updateChangeStepService(inquiry.id, params)
      .then(() => {
        toast.success('Auto de prisão arquivado com sucesso!');
        setOpenModalConfirm(false);
        history.goBack();
      })
      .finally(() => setLoadingArquivar(false));
  };

  return (
    <>
      {inquiry.inquiry?.classe === '280' ? (
        inquiry.inquiry?.deadline?.fulfilledAt ? (
          <ButtonConsensus
            title="ARQUIVAR"
            backgroundColor={colorsSteps.ARQUIVAR}
            hovercolor={colorsSteps.ARQUIVAR}
            onClick={() => (!write ? handleOpenSnackbar() : setOpenModalConfirm(true))}
            disabled={loadingArquivar}
          />
        ) : (
          <Tooltip
            arrow
            placement="top"
            title={withoutTooltipMessage ? '' : 'Vincule este auto de prisão para arquivar'}
          >
            <span>
              <ButtonConsensus
                title="ARQUIVAR"
                backgroundColor={colorsSteps.ARQUIVAR}
                hovercolor={colorsSteps.ARQUIVAR}
                disabled
              />
            </span>
          </Tooltip>
        )
      ) : null}

      {openModalConfirm && (
        <ModalConfirm
          title="Confirmação"
          open={openModalConfirm}
          handleClose={() => setOpenModalConfirm(false)}
          onClick={changeInquiryToArquivar}
          disabledConfirm={loadingArquivar}
          noCloseOnConfirm={true}
        >
          <Typography variant="h5">
            Deseja enviar este auto de prisão para a fase de arquivamento?
          </Typography>
        </ModalConfirm>
      )}
    </>
  );
};

ButtonArquivar.propTypes = {
  inquiry: PropTypes.object,
};

ButtonArquivar.defaultProps = {
  inquiry: {},
};

export default ButtonArquivar;
