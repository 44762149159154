const lecturesParser = lectures => {
  const newArrayLectures = [];

  if (lectures?.length > 0) {
    lectures.forEach(item => {
      const newLecture = {
        description: item.description,
        expectedDate: item.expectedDate,
        association: item.association,
        type: item.type,
        penalty: 'Palestra Socioeducativa',
        project: item.project,
      };

      if (item.link) {
        newLecture.link = item.link;
      }

      newArrayLectures.push(newLecture);
    });
  }

  return newArrayLectures;
};

export default lecturesParser;
